<div class="bg-neutral-90 h-8 flex text-white justify-between items-center">
  @if (onboardingStore.onboarding() && !onboardingStore.collapsed()) {
    <div class="bg-neutral-80 h-8 flex items-center justify-between px-4 w-44">
      <span class="subtitle-1">Onboarding</span>
      <span
        (click)="this.onboardingStore.isCollapsed(true)"
        class="fa-icons icon-regular icon-16 icon-xmark cursor-pointer"
      ></span>
    </div>
  } @else {
    <div class="flex items-center">
      @if (onboardingStore.onboarding() && onboardingStore.collapsed()) {
        <button
          kendoButton
          fillMode="flat"
          iconClass="svg-icons icon-onboarding icon-16 cursor-pointer"
          (click)="this.onboardingStore.isCollapsed(false)"
        ></button>
      }

      @if (!onboardingStore.onboarding()) {
        @if (!hideBack) {
          <span
            (click)="goBack()"
            class="fa-icons icon icon-regular icon-16 icon-chevron-left cursor-pointer pl-4"
          ></span>
        }
        <p class="subtitle-1 pl-4">{{ pageTitle }}</p>
      }
    </div>
  }
  @if (!showOnlyTitle) {
    <kendo-dropdownbutton
      fillMode="flat"
      iconClass="fa-icons icon icon-16 icon-bars"
      [data]="data"
      [disabled]="onboardingStore.onboarding() && !onboardingStore.collapsed()"
    >
      <ng-template kendoDropDownButtonItemTemplate let-dataItem>
        <div class="min-w-24">
          <span [class]="dataItem.icon"></span>
          <span class="ml-2">{{
            'outlook.menu.' + dataItem.id | translate
          }}</span>
        </div>
      </ng-template>
    </kendo-dropdownbutton>
  }
</div>

@if (onboardingStore.onboarding() && !onboardingStore.collapsed()) {
  <maximizer-outlook-stepper @slideDown></maximizer-outlook-stepper>
}
