import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity, Octopus } from '@maximizer/core/shared/domain';
import { ContextService } from './context.service';
import { EntityService } from './entity.service';
import { MetadataService } from './metadata.service';

@Injectable()
export class AddressBookService extends EntityService {
  constructor(
    http: HttpClient,
    context: ContextService,
    metadata: MetadataService,
  ) {
    super(Octopus.AbEntryEntityCode, http, context, metadata);
  }

  get readDriver(): Octopus.RequestConfiguration {
    return Octopus.AbEntryReadDriver;
  }

  get writeDriver(): Octopus.RequestConfiguration {
    return {
      Drivers: {},
    };
  }

  override getFieldsForDefaultEntry(): Octopus.Scope<Entity> {
    return {};
  }
}
