<maximizer-outlook-menu [id]="id" [pageType]="type"[pageTitle]="'outlook.task.add-title' | translate" ></maximizer-outlook-menu>
<div class="flex flex-col h-full">
  <maximizer-loader
    *ngIf="loading"
    type="legacy"
  ></maximizer-loader>
  <form
    *ngIf="form"
    class="flex flex-col p-4 text-xs space-y-2"
    [formGroup]="form.group"
  >
    <kendo-formfield>
      <kendo-label [for]="dueDate" [text]="('outlook.task.dueDate' | translate) + '*'">
      </kendo-label>
      <kendo-datetimepicker
        #dueDate
        [format]="fullFormat"
        formControlName="dueDate"
        calendarType="classic"
        required
      >
      </kendo-datetimepicker>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="('outlook.task.activity' | translate) + '*'">
      </kendo-label>
      <kendo-textarea
        [rows]="6"
        resizable="vertical"
        formControlName="activity"
      ></kendo-textarea>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.task.priority' | translate"></kendo-label>
      <kendo-dropdownlist
        [data]="configuration?.options?.priorities"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="priority"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="primaryContact"
        [text]="'outlook.task.assignedUser' | translate"
      >*
      </kendo-label>

      <kendo-dropdownlist
        [data]="configuration?.users"
        textField="name"
        valueField="id"
        fillMode="solid"
        [valuePrimitive]="true"
        formControlName="assignedUser"
        #primaryContact
      >
      </kendo-dropdownlist>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.task.category' | translate"></kendo-label>
      <kendo-dropdownlist
        [data]="configuration?.options?.categories"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="category"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.task.result' | translate"></kendo-label>
      <kendo-dropdownlist
        [data]="configuration?.options?.results"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="result"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>
  </form>

  <div class="bg-neutral-5 z-40 mt-auto flex space-x-2 p-2 w-full bottom-0 sticky">
    <button
      kendoButton
      class="uppercase rounded ml-auto"
      themeColor="primary"
      [disabled]="!canSave"
      (click)="save()"
    >
      <span *ngIf="!loading; else loader"
        >{{ 'outlook.buttons.save' | translate }}
      </span>
      <ng-template #loader>
        <kendo-loader type="pulsing" themeColor="primary" size="small">
        </kendo-loader>
      </ng-template>
    </button>
    <button
      kendoButton
      rounded="large"
      class="uppercase rounded"
      themeColor="primary"
      (click)="cancel()"
      [disabled]="loading"
      fillMode="flat"
    >
      {{ 'outlook.buttons.cancel' | translate }}
    </button>
  </div>
</div>


<maximizer-outlook-notification
  #notification
  [closable]="true"
  entryId="task"
  >
</maximizer-outlook-notification>
