const pattern_1 = /^([a-zA-Z0-9]?)([a-zA-Z0-9]{1})([a-zA-Z0-9]{3})([a-zA-Z0-9]{3})([a-zA-Z0-9]{4})$/g;
const pattern_2 = /^([a-zA-Z0-9]{3})([a-zA-Z0-9]{3})([a-zA-Z0-9]{4})$/g;
const pattern_3 = /^([a-zA-Z0-9]{1,2})([a-zA-Z0-9]{3})([a-zA-Z0-9]{4})$/g;
const pattern_4 = /^([a-zA-Z0-9]{1,3})([a-zA-Z0-9]{4})$/g;

export function doPhoneFormat(text: string, tapiFormat = true): string {
  if (!text) return text;
  
  if (pattern_1.test(text)) {
    if (tapiFormat) {
      text = text.replace(pattern_1, "$1+$2 ($3) $4-$5");
    } else {
      text = text.replace(pattern_1, "$1$2-$3-$4-$5");
    }
  } else if (pattern_2.test(text)) {
    if (tapiFormat) {
      text = text.replace(pattern_2, "($1) $2-$3");
    } else {
      text = text.replace(pattern_2, "$1-$2-$3");
    }
  } else if (pattern_3.test(text)) {
    if (tapiFormat) {
      text = text.replace(pattern_3, "$1) $2-$3");
    } else {
      text = text.replace(pattern_3, "$1-$2-$3");
    }
  } else if (pattern_4.test(text)) {
    text = text.replace(pattern_4, "$1-$2");
  }

  return text;
}