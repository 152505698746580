import { differenceInDays, endOfDay, isAfter } from 'date-fns';
import {
  EditableListItem,
  Field,
  Opportunity,
  OpportunitySearch,
} from '../../../models';
import {
  FieldOption,
  Opportunity as OctopusOpportunity,
  ValueAndDisplay,
} from '../models';
import { OpportunityStatus } from '../models/opportunity';
import { DefaultRevenueTypes } from '../models/revenue-type';
import { SalesStageSetupMapper } from './stage';
import { EntityMapper } from './entity';

export class OpportunityMapper extends EntityMapper<
  OctopusOpportunity,
  Opportunity
> {
  from(source: OctopusOpportunity, fields: Field[] = []): Opportunity {
    const opportunity: Opportunity = {
      key: source.Key,
      abEntry: {
        value: source.AbEntry?.Key ?? '',
        display: this.mapCompanyOrIndividual(source.AbEntry),
      },
      objective: source.Objective,
      description: source.Description,
      status: this.mapNumberAndDisplay(
        source.Status as ValueAndDisplay<number>,
      ),
      cost: this.mapCurrencyAndDisplay(source.Cost),
      actualRevenue: this.mapCurrencyAndDisplay(source.ActualRevenue),
      forecastRevenue: this.mapCurrencyAndDisplay(source.ForecastRevenue),
      corporateRevenue: this.mapCurrencyAndDisplay(source.CorporateRevenue),
      startDate: this.mapDateAndDisplay(source.StartDate),
      closeDate: this.mapDateAndDisplay(source.CloseDate),
      currentSalesStageAge: source.CurrentSalesStageAge,
      processId: source.SalesProcessSetupKey,
      stage: new SalesStageSetupMapper().from(source.SalesStageSetup),
      currentStage: source.CurrentSalesStage.DisplayValue,
      product: source.Product?.DisplayValue ?? '',
      addressBookId: source.AbEntry.Key ?? '',
      contact: {
        value: source.Contact?.Key ?? '',
        display: source.Contact
          ? `${source.Contact.FirstName} ${source.Contact.LastName}`.trim()
          : '',
      },
      owner: this.mapStringAndDisplay(source.Leader),
      weightedCorporateRevenue: Math.ceil(
        source.CorporateRevenue.Value *
          (source.SalesStageSetup.ProbabilityClose / 100),
      ),
      stageOverdueDays: null,
      ...this.mapFields(source, fields),
    };
    if (opportunity.closeDate?.value) {
      const now = new Date();
      opportunity.isOverdue = isAfter(
        now,
        endOfDay(opportunity.closeDate.value),
      );
      if (opportunity.isOverdue) {
        opportunity.overdueDays = differenceInDays(
          endOfDay(now),
          endOfDay(opportunity.closeDate.value),
        );
      }
    }

    if (
      opportunity.stage?.targetAge &&
      opportunity.currentSalesStageAge > opportunity.stage.targetAge
    ) {
      opportunity.stageOverdueDays = Math.ceil(
        opportunity.currentSalesStageAge - opportunity.stage.targetAge,
      );
    }

    return opportunity;
  }

  mapRevenueTypeForEdit(source: FieldOption): EditableListItem<string> {
    const key = source.Key ?? '';
    const isDefault = DefaultRevenueTypes.isDefaultRevenueType(key);

    return {
      id: key,
      name: source.DisplayValue,
      isActive: !source.InActive,
      isEditing: false,
      canDelete: !isDefault,
      canEdit: !isDefault,
    };
  }
}

export class OpportunitySearchMapper extends EntityMapper<
  OctopusOpportunity,
  OpportunitySearch
> {
  from(source: OctopusOpportunity): OpportunitySearch {
    return {
      key: source.Key,
      status: source.Status as OpportunityStatus,
      displayValue: source.DisplayValue,
      contactName: source.Contact
        ? `${source.Contact.FirstName} ${source.Contact.LastName}`.trim()
        : '',
      contactKey: source.Contact ? source.Contact.Key : null,
      companyKey: source.AbEntry.Key ?? null,
      companyName: this.mapCompanyOrIndividual(source.AbEntry),
      objective: source.Objective,
      lastContactDateDisplayValue: source.LastModifyDate?.DisplayValue,
      lastContactDateValue: source.LastModifyDate?.Value,
      revenueType: source.RevenueType as string,
    };
  }
}
