/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Entity,
  Octopus,
  ListItem,
  Field,
  SelectorField,
  DateTimeField,
} from '@maximizer/core/shared/domain';
import { isObject } from '@maximizer/shared/util';
import { formatISO, parseISO } from 'date-fns';
import { LayoutFormGroup } from '../classes/layout-form-group';

export interface ValueHandler {
  form: LayoutFormGroup;
  getEntityValue(): Entity;
  setFormValue(data: Entity): void;
  setValue(
    id: string,
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ): void;
}

export class OctopusValueHandler implements ValueHandler {
  constructor(public form: LayoutFormGroup) {}

  setValue(
    key: string,
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ): void {
    const control = this.form.getControl(key);

    if (
      control?.field.metadata?.type ===
      Octopus.FieldType.EnumFieldOfPartnerCompetitor
    ) {
      const currentValue = (control?.value as ListItem<string>[]) ?? [];
      if (!currentValue.find((item) => item.id === value.id)) {
        control?.setValue([...currentValue, { ...value, description: '' }]);
      }
    } else {
      if (control?.field.type === 'selector') {
        const selector = control.field as SelectorField<unknown>;
        if (selector.selection === 'dialog') {
          selector.options = isObject(value) ? [value] : [];
        }
      }

      this.form.patchValue(
        {
          [key]: value,
        },
        options,
      );
    }
  }

  getEntityValue(): Entity {
    const result: Entity = {};

    Object.values(this.form.fields).forEach((field) => {
      const control = this.form.getControl(field.id);

      if (!field.readonly) {
        let entity = result;
        let key = field.id;
        const value = control?.value;
        const tree = field.metadata?.isCustom ? [] : key.split('/');

        if (tree.length > 1) {
          key = tree.splice(tree.length - 1, 1)[0];
          tree.forEach((path) => {
            if (!entity[path]) {
              entity[path] = {};
            }
            entity = entity[path] as Entity;
          });
        }

        switch (field.metadata?.type) {
          case Octopus.FieldType.AbEntryObject:
          case Octopus.FieldType.CampaignObject:
          case Octopus.FieldType.SalesTeamObject:
            this.setEntityKeyValue(entity, key, value);
            break;

          case Octopus.FieldType.SalesProcessSetupObject:
          case Octopus.FieldType.SalesStageSetupObject:
            this.setEntityKeyValue(entity, key, value);
            this.setEntityDescriptionValue(entity, key, value, field);
            break;

          case Octopus.FieldType.CurrencyField:
            this.setEntityCurrencyValue(entity, key, value);
            break;

          case Octopus.FieldType.DateTimeField:
            this.setEntityDateTimeValue(entity, key, value, field);
            break;

          case Octopus.FieldType.EnumFieldOfPartnerCompetitor:
            this.setEntityPartnerCompetitorValue(entity, key, value);
            break;

          case Octopus.FieldType.StringField:
            this.setEntityStringValue(entity, key, value);
            break;

          default:
            this.setEntityDefaultValue(entity, key, value);
            break;
        }
      }
    });

    return result;
  }

  private setEntityKeyValue(entity: Entity, key: string, value: any): void {
    if (this.form.fields[key + 'Key']) {
      key += 'Key';
    }

    entity[key] = value?.id ?? value ?? null;
    this.form.patchValue({ [key]: entity[key] }, { emitEvent: false });
  }

  private setEntityDescriptionValue(
    entity: Entity,
    key: string,
    value: any,
    field: Field,
  ): void {
    const selector = field as SelectorField<unknown>;
    const description = selector.options?.find(
      (item) => item.id === value,
    )?.name;
    entity[key] = { Description: description ?? '' };
  }

  private setEntityDateTimeValue(
    entity: Entity,
    key: string,
    value: Date,
    field: Field,
  ): void {
    const dateTimeField = field as DateTimeField;

    entity[key] = value
      ? formatISO(value, {
          representation:
            dateTimeField.selection === 'dateTime'
              ? 'complete'
              : dateTimeField.selection,
        })
      : null;
  }

  private setEntityCurrencyValue(
    entity: Entity,
    key: string,
    value: number,
  ): void {
    const currency = (entity[key] ?? {}) as Octopus.CurrencyValue;
    currency.Value = value;
    entity[key] = currency;
  }

  private setEntityPartnerCompetitorValue(
    entity: Entity,
    key: string,
    value: ListItem<string>[],
  ): void {
    entity[key] = Array.isArray(value)
      ? value.map((item: ListItem<string>) => {
          return {
            Key: item.id,
            Comment: item.description,
          } as Octopus.PartnerCompetitor;
        })
      : null;
  }

  private setEntityStringValue(entity: Entity, key: string, value: any): void {
    entity[key] = value ? value.trim() : null;
  }

  private setEntityDefaultValue(entity: Entity, key: string, value: any): void {
    if (Array.isArray(value)) {
      entity[key] = value.map((item) => item.id);
    } else if (isObject(value)) {
      entity[key] = value?.id ?? null;
    } else {
      entity[key] = value ?? null;
    }
  }

  setFormValue(data: Entity): void {
    const entity: Entity = {};

    Object.keys(data).forEach((key) => {
      const control = this.form.getControl(key);
      if (control) {
        const value = data[key] as Entity;
        if (value) {
          switch (control.field.metadata?.type) {
            case Octopus.FieldType.AbEntryObject:
            case Octopus.FieldType.CampaignObject:
              this.setFormKeyAndDisplayValue(entity, key, value);
              break;

            case Octopus.FieldType.AbEntryKey:
            case Octopus.FieldType.CampaignKey:
            case Octopus.FieldType.OpportunityKey:
              this.setFormValueAndDisplayValue(entity, key, value);
              break;

            case Octopus.FieldType.CurrencyField:
              this.setFormCurrencyValue(entity, key, value);
              break;

            case Octopus.FieldType.DateTimeField:
              this.setFormDateTimeValue(entity, key, value);
              break;

            case Octopus.FieldType.DurationField:
              this.setFormDisplayValue(entity, key, value);
              break;

            case Octopus.FieldType.EnumFieldOfPartnerCompetitor:
              this.setFormPartnerCompetitorValue(entity, key, value);
              break;

            default:
              this.setFormDefaultValue(control.field, entity, key, value);
              break;
          }

          if (control.field.type === 'selector') {
            this.setOptionsFromValue(
              control.field as SelectorField<unknown>,
              value,
            );
          }
        } else {
          entity[key] = null;
        }
      }
    });

    this.form.patchValue(entity);
  }

  private setOptionsFromValue(
    selector: SelectorField<unknown>,
    value: Entity,
  ): void {
    if (!selector.options.length) {
      let options: ListItem<string>[] = [];

      if (value['Items']) {
        options = this.getValueFromItems(value);
      } else {
        options = [
          {
            id: (value['Key'] as string) ?? (value['Value'] as string) ?? '',
            name: (value['DisplayValue'] as string) ?? '',
          },
        ];
      }
      selector.options = options;
    }
  }

  private setFormKeyAndDisplayValue(
    entity: Entity,
    key: string,
    value: Entity,
  ): void {
    entity[key] = value['Key']
      ? {
          id: value['Key'] as string,
          name: value['DisplayValue'] as string,
        }
      : null;
  }

  private setFormValueAndDisplayValue(
    entity: Entity,
    key: string,
    value: Entity,
  ): void {
    entity[key] = value['Value']
      ? {
          id: value['Value'] as string,
          name: value['DisplayValue'] as string,
        }
      : null;
  }

  private setFormCurrencyValue(
    entity: Entity,
    key: string,
    value: Entity,
  ): void {
    entity[key] = value['Value'];
    entity[`${key}/CurrencyCode`] = value['CurrencyCode'];
  }

  private setFormDateTimeValue(
    entity: Entity,
    key: string,
    value: Entity,
  ): void {
    if (value['Value']) {
      entity[key] = parseISO(value['Value'] as string);
    }
  }

  private setFormDisplayValue(
    entity: Entity,
    key: string,
    value: Entity,
  ): void {
    entity[key] = value['DisplayValue'];
  }

  private setFormPartnerCompetitorValue(
    entity: Entity,
    key: string,
    value: Entity,
  ): void {
    entity[key] = (
      (value['Items'] as Array<Octopus.PartnerCompetitor>) ?? []
    ).map((item) => {
      return {
        id: item.Key,
        name: item.DisplayValue,
        description: item.Comment,
      };
    });
  }

  private setFormDefaultValue(
    field: Field,
    entity: Entity,
    key: string,
    value: Entity,
  ): void {
    if (value['Items']) {
      const values = this.getValueFromItems(value);

      if (
        field.type === 'selector' &&
        (field as SelectorField<unknown>).selection === 'multiple'
      ) {
        entity[key] = values;
      } else if (values.length) {
        entity[key] = values[0].id;
      }
    } else {
      entity[key] = value['Key'] ?? value['Value'];
    }
  }

  private getValueFromItems(value: Entity): ListItem<string>[] {
    return ((value['Items'] as Array<Octopus.KeyAndDisplay>) ?? []).map(
      (item) => {
        return {
          id: item.Key,
          name: item.DisplayValue,
        };
      },
    );
  }
}
