import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { DomainModule } from '@maximizer/core/shared/domain';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookService } from './outlook/outlook.service';
import { AbEntryService, LeadService, OutlookSyncService } from './services';
import { OnboardingStore } from './state';

export const INTEGRATION_URL = new InjectionToken<string>(
  'integrationEntrypoint',
);
export const INTEGRATION_SUBSCRIPTION_KEY = new InjectionToken<string>(
  'integrationSubscriptionKey',
);
export const APP_STORE_OAUTH_URL = new InjectionToken<string>(
  'appStoreOAuthUrl',
);
@NgModule({
  imports: [CommonModule, DomainModule, OutlookSharedDomainModule],
})
export class OutlookSharedDataAccessModule {
  static forRoot(
    url: string,
    subscriptionKey: string,
    appStoreOAuthUrl: string,
  ): ModuleWithProviders<OutlookSharedDataAccessModule> {
    return {
      ngModule: OutlookSharedDataAccessModule,
      providers: [
        AbEntryService,
        LeadService,
        OutlookService,
        OutlookSyncService,
        OnboardingStore,
        {
          provide: INTEGRATION_URL,
          useValue: url,
        },
        {
          provide: INTEGRATION_SUBSCRIPTION_KEY,
          useValue: subscriptionKey,
        },
        {
          provide: APP_STORE_OAUTH_URL,
          useValue: appStoreOAuthUrl,
        },
      ],
    };
  }
}
