import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContextService,
  GlobalStore,
  OAuth2Service,
  SessionService,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import { OnboardingStore } from '@maximizer/outlook/shared/data-access';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'maximizer-outlook-menu',
  templateUrl: './menu.component.html',
  animations: [
    trigger('slideDown', [
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        animate('300ms ease-in-out', style({ height: '*', opacity: 1 })),
      ]),
    ]),
  ],
})
export class OutlookMenuComponent implements OnInit {


  @Input() pageTitle = '';
  @Input() pageType!: 'lead' | 'abentry' | 'none';
  @Input() id = '';

  @Input() showOnlyTitle = false;
  @Input() hideBack = false;
  @Input() hideRefresh = false;
  @Input() hideLogout = false; 
  @Input() backRoutePath = '';
  @Input() forceWindowRefresh = false;


  deepLink = '';

  data = [
    {
      id: 'refresh',
      icon: 'fa-icons icon icon-solid icon-14 icon-refresh',
      click: () => this.refreshWindow(),
    },
    {
      id: 'maximizer',
      icon: 'fa-icons icon icon-solid icon-14 icon-arrow-up-right-from-square',
      click: () => {
        if(!this.deepLink) this.deepLink = 'https://www.maximizer.com';
        window.open(this.deepLink, '_blank');
      },
    },
    {
      id: 'logout',
      icon: 'fa-icons icon icon-solid icon-14 icon-right-from-bracket',
      click: () => {
        this.logout();
        this.onboardingStore.showOnboarding(false);
      },
    },
  ];

  constructor(
    private contextService: ContextService,
    private session: SessionService,
    private location: Location,
    private router: Router,
    private globalStore: GlobalStore,
    private oauth2: OAuth2Service,
    public onboardingStore: OnboardingStore,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.hideRefresh) this.data = this.data.slice(1);
    if (this.hideLogout) this.data.pop();
    if (this.showOnlyTitle) {
      this.hideBack = true;
      this.data = [];
    }

    if (this.contextService.token) {
      const session = this.globalStore.session();
      if (session?.alias) {
        this.populateDeeplink(session.alias);
      } else {
        this.session.getInfo().subscribe((data) => {
          this.populateDeeplink(data.alias);
        });
      }
    }
  }

  private populateDeeplink(alias: string): void {
    const urlArray = this.contextService.website.split('/');
    const domainIndex = urlArray.findIndex((o) =>
      o.toLocaleLowerCase().includes('maximizer'),
    );
    if (domainIndex == -1) return;
    let domain = '';
    for (let i = 0; i <= domainIndex; i++) {
      domain += urlArray[i] + '/';
    }
    const accountLink = domain?.replace('1', 'w') + alias;
    switch (this.pageType) {
      case 'lead':
        this.deepLink = `${accountLink}?sslead=KEY(${this.id})`;
        break;
      case 'abentry':
        this.deepLink = `${accountLink}?ss=KEY(${this.id})`;
        break;
      default:
        this.deepLink = accountLink;
        break;
    }
  }

  private refreshWindow(): void{ 
    if (this.forceWindowRefresh) {
      window.location.href = window.location.origin + window.location.pathname;
      return;
    }
    const path = this.router.url.split('?')[0];
    const queryParamStr = this.router.url.split('?')[1];
    const queryParams = queryParamStr ? queryParamStr.split('&') : [];

    const url = self
      ? decodeURIComponent(this.router.url)
      : decodeURIComponent(this.location.path());
    const refreshUrl = url == '/' ? '../' : '/';
    this.router
      .navigateByUrl(refreshUrl, { skipLocationChange: false })
      .then(() => {
        const pathDecomposed = decodeURIComponent(path)
        const obj = Object.fromEntries(
          queryParams.map((item) => {
            const [key, value] = item.split('=');
            return [key, decodeURIComponent(value)];
          })
        ) as Record<string, string>;
        this.router.navigate([pathDecomposed], { queryParams: obj});
      });
  }

  goBack() {
    if(this.backRoutePath){
      this.router.navigate([this.backRoutePath]);
    } else { 
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  logout() {
    this.oauth2.clearAuth();
    this.session.clearSessionCache();
    this.contextService.clearAuthentication();
    this.sessionStorage.clear();

    window.location.href = window.location.origin + '/login';
  }
}
