import { EntityCode } from './entity-type';
import { Metadata } from './metadata';
import { ReadRequest, Request, WriteRequest } from './request';
import {
  DataResponse,
  Response,
  ValidationResponse,
  WriteResponse,
} from './response';

export enum EntityFields {
  CreationDate = 'CreationDate',
  Creator = 'Creator',
  DisplayValue = 'DisplayValue',
  Description = 'Description',
  Key = 'Key',
  LastModifyDate = 'LastModifyDate',
  ModifiedBy = 'ModifiedBy',
  SecAccess = 'SecAccess',
  SecStatus = 'SecStatus',
}

export interface Entity {
  [key: string]: unknown;
}

export interface EntityCount {
  '$Count(Key)'?: number | null;
}

export interface EntityWithMetadata extends Entity {
  [key: string]: { $Metadata: Metadata };
}

export type EntityRequest = Partial<{
  [key in EntityCode]: ReadRequest<Entity>;
}> &
  Request;

export type EntityResponse = Partial<{
  [key in EntityCode]: DataResponse<Entity>;
}> &
  Response;

export type EntityResponseWithMetadata = Partial<{
  [key: string]: DataResponse<EntityWithMetadata>;
}> &
  Response;

export type EntityWriteRequest = Partial<{
  [key in EntityCode]: WriteRequest<Entity>;
}> &
  Request;

export type EntityWriteResponse = Partial<{
  [key in EntityCode]: WriteResponse<Entity>;
}> &
  Request;

export type EntityValidationResponse = Partial<{
  [key in EntityCode]: ValidationResponse<Entity>;
}> &
  Response;
