import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContextService,
  DocumentService,
  FeatureService,
  GlobalStore,
  OAuth2Service,
  SessionService,
} from '@maximizer/core/shared/data-access';
import {
  CDN_URL,
  CreateDocument,
  Session,
  DocumentType,
  GlobalServicesLoginDetails,
} from '@maximizer/core/shared/domain';
import { InsightsService } from '@maximizer/core/shared/insights';
import { InteractionService } from '@maximizer/outlook/interaction-log/data-access';
import { OpportunityService } from '@maximizer/outlook/opportunity/data-access';
import {
  AbEntryService,
  EmailSubscription,
  LeadService,
  OutlookService,
  OutlookSyncService,
  EntityType,
  APP_STORE_OAUTH_URL,
  DataCenterType,
  mapDataCenterFromToken,
  buildApiWebRequest,
} from '@maximizer/outlook/shared/data-access';
import {
  OutlookLeadSearch,
  OutlookAbEntryDetails,
  OpportunityRevenueSum,
  OutlookEmail,
} from '@maximizer/outlook/shared/domain';
import {
  OutlookNotificationComponent,
  OutlookNotificationType,
} from '@maximizer/outlook/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  Subscription,
  catchError,
  finalize,
  firstValueFrom,
  forkJoin,
  of,
  map,
  switchMap,
} from 'rxjs';
import { DisposableComponent } from '@maximizer/core/shared/ui';

export interface AppStoreRedirectParams {
  webApiUrlRequest: string;
  userId: string;
  alias: string;
  dataCenterId: DataCenterType;
  customerId: string;
  tenantPlatform: number;
  tenantName: string;
  tenantKey: string;
  workspace: string;
}

@Component({
  selector: 'maximizer-entry-page',
  templateUrl: './entry-page.component.html',
})
export class EntryPageComponent
  extends DisposableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @HostBinding('class.max-outlook') hostClass = true;

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.closeTooltips();
  }

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Input() type!: 'abentry' | 'lead';
  private _id = '';

  @Input()
  set id(id: string) {
    this._id = decodeURI(id);
  }
  get id(): string {
    return this._id;
  }

  pageTitle = '';
  deepLink = '';
  oppLink = '';
  companyDeepLink = '';

  entryType = '';
  abentry?: OutlookAbEntryDetails;
  lead?: OutlookLeadSearch;
  opportunities?: OpportunityRevenueSum;
  resourceName = '';
  showSkeleton = true;
  saveEmailLoading = false;

  lastContactDays: number | null = null;
  lastContactMessage: string | null = null;

  disabled = false;

  showSaveEmail = false;
  subscription?: Subscription;
  outlookEmail?: OutlookEmail;
  loadingSavedEmail = true;
  refreshTimelineEmitter = new EventEmitter<void>();

  showAutoSaveEmailToggle = false;
  autoSaveDisabled = true;
  autoSave = false;
  syncId?: string;
  syncLoading = true;
  outlookSyncInstalled = false;

  logInDetails?: GlobalServicesLoginDetails | null;

  get emailIsSaved(): boolean {
    return (!this.loadingSavedEmail && this.outlookEmail?.emailSaved) ?? false;
  }

  constructor(
    @Inject(CDN_URL) public cdn: string,
    @Inject(APP_STORE_OAUTH_URL) private appStoreUrl: string,
    private translate: TranslateService,
    private abentryService: AbEntryService,
    private leadService: LeadService,
    private sessionService: SessionService,
    private contextService: ContextService,
    private interactionService: InteractionService,
    private opportunityService: OpportunityService,
    private outlookService: OutlookService,
    private documentService: DocumentService,
    private activateRoute: ActivatedRoute,
    private outlookSyncService: OutlookSyncService,
    private insightsService: InsightsService,
    private router: Router,
    private oAuthService: OAuth2Service,
    public feature: FeatureService,
    public globalStore: GlobalStore,
  ) {
    super();
    this.showAutoSaveEmailToggle = feature.isFeatureOn(
      'microsoft-outlook-sync-emails-shown',
      false,
    );
  }

  ngOnInit() {
    if (!this.type) {
      this.entryNotFound();
      return;
    }
    this.showSaveEmail = !this.outlookService.isCompose;
    this.activateRoute.paramMap.subscribe((o) => {
      this.id = o.get('id') ?? '';
    });
    this.decomposeId();
    this.loadData();
    this.logInDetails = this.oAuthService.getStorageLoginDetails();
  }

  ngAfterViewInit() {
    this.notification.resourceId = this.entryType ?? 'contact';
    this.pageTitle = this.translate.instant(
      'outlook.entries.' + (this.entryType ?? 'contact'),
    );
  }

  override ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private decomposeId() {
    try {
      const indexQuestion = this.id.indexOf('?');
      if (indexQuestion > -1) {
        this.id = this.id.substring(0, indexQuestion);
      }
      const decomposedId = window.atob(this.id);
      const decomposedItems = decomposedId.split('\t');
      this.entryType = decomposedItems[0]?.toLocaleLowerCase();
    } catch (error) {
      this.insightsService.trackException({ error: error as Error });
      this.entryNotFound();
    }
  }

  private entryNotFound() {
    this.notification.resourceId = this.entryType ?? 'contact';
    this.notification?.show('load', 'error');
    this.disabled = true;
    this.router.navigate(['/home']);
  }

  private loadData() {
    const opportunity =
      this.type == 'abentry'
        ? this.opportunityService.getOpenAndWonSum(this.id)
        : of(null);

    forkJoin({
      session: this.sessionService.getInfo(),
      entry: this.loadLeadOrAbEntryService(),
      lastContactDate: this.interactionService.getLastContact(
        this.id,
        this.type,
      ),
      opportunityDetails: opportunity,
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
          this.disabled = true;
        }),
        finalize(() => {
          this.showSkeleton = false;
          this.disabled = !this.lead && !this.abentry;
        }),
      )
      .subscribe(async (data) => {
        if (data == null) return;

        if (data.session) {
          this.populateDeepLinks(data.session);
          this.getOutlookSyncSettings(data.session);
        }

        if (this.type === 'lead') {
          await this.loadLead(
            data.entry as OutlookLeadSearch,
            data.lastContactDate,
          );
        }

        if (this.type === 'abentry') {
          await this.loadAbEntry(
            data.entry as OutlookAbEntryDetails,
            data.session,
          );
        }

        if (data.opportunityDetails) {
          this.opportunities = data.opportunityDetails;
        }
      });
  }

  populateDeepLinks(session: Session): void {
    const domain = this.extractDomain();
    const accountLink = domain?.replace('1', 'w') + session.alias;
    this.deepLink = `${accountLink}?ss=KEY(${this.id})`;
    this.oppLink = `${accountLink}?ssopp=KEY(${this.id})`;
  }

  getOutlookSyncSettings(session: Session): void {
    this.syncLoading = true;
    this.outlookSyncService
      .getConfiguration(session.user.id, session.workspace)
      .pipe(
        switchMap((config) => {
          if (!config) {
            this.outlookSyncInstalled = false;
            return of(null);
          }

          this.outlookSyncInstalled = config.enabled;
          this.syncId = config?.id;
          this.autoSaveDisabled = !config?.emailsSync.enabled;

          return this.outlookSyncService.getEmailSubscriptionByKey(
            this.id,
            this.syncId,
          );
        }),
        map((emailSubscription) => {
          if (emailSubscription) {
            this.autoSave = emailSubscription.enabled;
          }
        }),
        finalize(() => {
          this.syncLoading = false;
        }),
      )
      .subscribe();
  }

  private loadLeadOrAbEntryService(): Observable<
    OutlookAbEntryDetails | OutlookLeadSearch | null
  > {
    if (this.type == 'lead') return this.leadService.getByKey(this.id ?? '');
    if (this.type == 'abentry')
      return this.abentryService.getAllDetailsByKey(this.id ?? '');
    return of(null);
  }

  private async loadAbEntry(abentry: OutlookAbEntryDetails, session: Session) {
    if (abentry.type === 'Company') {
      abentry.name = abentry.companyName;
      abentry.companyName = '';
    }
    this.abentry = abentry;
    this.daysAgo(abentry.lastContactDateValue);
    this.lastContactMessage = this.getDaysAgoTranslation();

    if (abentry.parentKey) {
      const domain = this.extractDomain();
      const accountLink = domain?.replace('1', 'w') + session.alias;
      this.companyDeepLink = `${accountLink}?ss=KEY(${abentry.parentKey})`;
    }
    try {
      this.outlookEmail = {
        id: abentry.key ?? this.id,
        emailAddress: abentry.emails ? abentry.emails[0].value : '',
        displayName: abentry.name,
        type: 'abentry',
        abentryType: abentry.type.toLocaleLowerCase() as
          | 'company'
          | 'contact'
          | 'individual'
          | undefined,
      };

      if (!this.showSaveEmail) return;
      this.outlookEmail.emailSaved =
        await this.outlookService.checkSavedEmailByKey(this.id);
    } catch (error) {
      this.insightsService.trackException(
        { exception: error as Error },
        { message: 'Error on load abentry' },
      );
    } finally {
      this.loadingSavedEmail = false;
    }
  }

  private async loadLead(
    lead: OutlookLeadSearch,
    lastContactDate: Date | null,
  ) {
    try {
      this.lead = lead;
      this.daysAgo(lastContactDate);
      this.lastContactMessage = this.getDaysAgoTranslation();
      this.deepLink = this.deepLink?.replace('?ss=KEY', '?sslead=KEY');

      this.outlookEmail = {
        id: this.id,
        emailAddress: lead.email ?? '',
        displayName: lead.name,
        type: 'lead',
        abentryType: undefined,
      };

      if (!this.showSaveEmail) return;
      const isSaved = await this.outlookService.checkSavedEmailByKey(this.id);
      this.outlookEmail.emailSaved = isSaved;
    } catch (error) {
      this.insightsService.trackException(
        { exception: error as Error },
        { message: 'Error on load lead' },
      );
    } finally {
      this.loadingSavedEmail = false;
    }
  }

  private extractDomain(): string {
    const urlArray = this.contextService.website.split('/');
    const domainIndex = urlArray.findIndex((o) =>
      o.toLocaleLowerCase().includes('maximizer'),
    );
    if (domainIndex == -1) return '';
    let domain = '';
    for (let i = 0; i <= domainIndex; i++) {
      domain += urlArray[i] + '/';
    }
    return domain;
  }

  daysAgo(value: Date | undefined | null): void {
    if (!value) {
      this.lastContactDays = null;
      return;
    }
    const currentDate = new Date();
    const targetDate = new Date(value);
    const diffInTime = currentDate.getTime() - targetDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
    if (isNaN(diffInDays)) {
      this.lastContactDays = null;
    } else {
      this.lastContactDays = diffInDays;
    }
  }

  getDaysAgoTranslation(): string {
    if (this.lastContactDays == null) return 'outlook.entry-page.notContacted';
    if (this.lastContactDays < 0) return '';
    if (this.lastContactDays == 0) return 'outlook.entry-page.today';
    return 'outlook.entry-page.daysago';
  }

  async saveEmailForEntry(): Promise<void> {
    if (this.outlookEmail?.id === undefined) return;

    try {
      this.saveEmailLoading = true;

      const emlFile = await this.outlookService.getReadEmlFile();
      if (!emlFile) throw new Error('Unable to get eml file');

      const documentId = await this.createEmailInMaximizer(emlFile, this.id);
      if (!documentId) {
        this.showNotificationMessage(
          'error',
          'outlook.email-notification.fail-entry',
        );
        return;
      }

      this.outlookEmail.emailSaved = true;
      this.showNotificationMessage(
        'success',
        'outlook.email-notification.success-entry',
      );
      await this.outlookService.saveKeysInOutlook([this.outlookEmail], false);
      this.refreshTimelineEmitter.emit();
    } catch (error) {
      console.error('Error', error);
      this.showNotificationMessage(
        'error',
        'outlook.email-notification.fail-entry',
      );
    } finally {
      this.saveEmailLoading = false;
    }
  }

  private async createEmailInMaximizer(
    emlFile: File,
    parentKey: string,
  ): Promise<string | null> {
    const attachment$ = this.documentService.createAttachment(
      emlFile,
      'application/octet-stream',
    );
    const attachmentId = await firstValueFrom(attachment$);

    const item = this.outlookService.mailboxItem;
    if (!item) return null;

    const document: CreateDocument = {
      name: item.subject,
      binaryDataId: attachmentId ?? '',
      dateTime: item.dateTimeCreated,
      extension: '.eml',
      type: DocumentType.Emails,
      size: emlFile.size,
      parentKey: parentKey,
    };

    const document$ = this.documentService.createDocument(document);
    return firstValueFrom(document$);
  }

  onAutoSaveEmailChange(isEnabled: boolean): void {
    if (!this.syncId) {
      return;
    }

    this.syncLoading = true;

    const entityType: EntityType =
      this.type === 'abentry' ? EntityType.AbEntry : EntityType.Lead;

    const emailSubscription: EmailSubscription = {
      maximizerEntityType: entityType,
      maximizerKey: this.id,
      enabled: isEnabled,
    };

    this.outlookSyncService
      .updateEmailAutoSaveSubscription(this.syncId, emailSubscription)
      .pipe(
        catchError((error) => {
          this.handleEmailAutoSaveError(isEnabled, error);
          return of(null);
        }),
        finalize(() => {
          this.syncLoading = false;
        }),
      )
      .subscribe((response) => {
        if (response === null) {
          return;
        }

        this.autoSave = isEnabled;

        if (isEnabled) {
          this.showNotificationMessage(
            'success',
            'outlook.entry-page.autoSave.notification',
          );
        }
      });
  }

  private handleEmailAutoSaveError(isEnabled: boolean, error: Error): void {
    this.autoSave = !isEnabled;

    this.showNotificationMessage('error', 'outlook.entry-page.autoSave.error');

    console.error('Error updating auto-save subscription:', error);
    this.insightsService.trackException(
      { error },
      { message: 'Failed updating auto-save subscription' },
    );
  }

  buildAppStoreFormRequest(): void {
    const appStoreForm = document.createElement('form');
    appStoreForm.target = 'view';
    appStoreForm.method = 'post';
    appStoreForm.action = this.appStoreUrl;

    const urlParams = this.constructAppStoreParams();

    if (!urlParams) {
      console.error('Could not open AppStore, missing parameters.');
      this.showNotificationMessage('error', 'appStore.error');
      this.closeTooltips();
      return;
    }

    const dataParametersInput = document.createElement('input');
    dataParametersInput.type = 'hidden';
    dataParametersInput.name = 'DataParameters';
    dataParametersInput.value = urlParams;

    appStoreForm.appendChild(dataParametersInput);
    document.body.appendChild(appStoreForm);
    this.handleAppStoreFormSubmission(appStoreForm);
  }

  constructAppStoreParams(): string | undefined {
    const { token, tenantId: customerId, alias } = this.contextService;
    const dataCenterId = mapDataCenterFromToken(token);
    const {
      user,
      database: databaseId,
      workspace,
    } = this.globalStore.session() ?? {};

    if (
      !dataCenterId ||
      !user ||
      !customerId ||
      !this.logInDetails ||
      !databaseId ||
      !workspace
    ) {
      return undefined;
    }

    const params: AppStoreRedirectParams = {
      webApiUrlRequest: buildApiWebRequest(dataCenterId, alias),
      userId: user.id,
      alias,
      dataCenterId,
      customerId,
      tenantPlatform: 0,
      tenantName: this.logInDetails.tenant.name,
      tenantKey: databaseId,
      workspace: workspace,
    };

    return JSON.stringify(params);
  }

  handleAppStoreFormSubmission(appStoreForm: HTMLFormElement): void {
    try {
      appStoreForm.submit();
    } catch (error) {
      const typedError = error as Error;
      console.error('Failed to open AppStore:', typedError);
      this.insightsService.trackException(
        { error: typedError },
        { message: 'Failed to post form to open AppStore' },
      );
      this.closeTooltips();
      this.showNotificationMessage('error', 'outlook.appStore.error');
    } finally {
      this.closeTooltips();
      document.body.removeChild(appStoreForm);
    }
  }

  private showNotificationMessage(
    type: OutlookNotificationType,
    message: string,
  ): void {
    const translatedMessage = this.translate.instant(message);
    this.notification.showMessage(type, translatedMessage);
  }
}
