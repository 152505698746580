import { NgModule } from '@angular/core';
import { EllipsisTooltipDirective } from './ellipsis-tooltip.directive';
import { TemplateDirective } from './template.directive';
import { ScrollEventDirective } from './scroll-event.directive';
import { KeyEventDirective } from './key-event.directive';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({
  exports: [
    EllipsisTooltipDirective,
    TemplateDirective,
    ScrollEventDirective,
    KeyEventDirective,
    ClickOutsideDirective,
  ],
  declarations: [
    EllipsisTooltipDirective,
    TemplateDirective,
    ScrollEventDirective,
    KeyEventDirective,
    ClickOutsideDirective,
  ],
})
export class UiDirectivesModule {}
