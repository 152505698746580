/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnChanges } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Field } from '@maximizer/core/shared/domain';
import { DisposableComponent } from '../../components/base/disposable.component';

@Component({
  selector: 'maximizer-display-field',
  template: ` <kendo-formfield [ngClass]="field.labelPosition ?? 'left'">
    <kendo-label
      maximizerFieldLabel
      [field]="field"
      [for]="input"
      [required]="field.required ?? false"
    >
    </kendo-label>
    <kendo-textbox
      #input
      fillMode="none"
      class="border-none hover:shadow-none focus-within:shadow-none"
      [readonly]="true"
      [value]="value"
      [ngClass]="field.cssClass"
      [tabindex]="-1"
    ></kendo-textbox>
  </kendo-formfield>`,
  styles: [
    `
      :host {
        @apply w-full;

        &::ng-deep {
          .k-input-inner {
            @apply px-0;
          }
        }
      }
    `,
  ],
})
export class DisplayFieldComponent
  extends DisposableComponent
  implements OnChanges
{
  @Input({ required: true })
  field!: Field;

  value = '';

  constructor(public form: FormGroupDirective) {
    super();
  }

  ngOnChanges(): void {
    const control = this.form.control.get(this.field.id);
    if (control) {
      this.setValue(control.value);
      this.addSubscription(
        control.valueChanges.subscribe((value) => {
          this.setValue(value);
        }),
      );
    }
  }

  private setValue(value: any): void {
    this.value = value?.name ?? value?.id ?? value ?? '';
  }
}
