import { Injectable } from '@angular/core';
import {
  ContextService,
  FeatureService,
  GlobalStore,
  OAuth2Service,
  SessionService
} from '@maximizer/core/shared/data-access';
import { catchError, lastValueFrom, of, retry, tap, timeout } from 'rxjs';
import { InsightsService } from '@maximizer/core/shared/insights';
import { OnboardingStore } from '@maximizer/outlook/shared/data-access';

@Injectable({
  providedIn: 'root',
})
export class OutlookAuthService {

  private get officeEmail(): string {
    return (
      Office.context.mailbox?.userProfile?.emailAddress?.toLocaleLowerCase() ??
      'mx_default'
    );
  }


  private get isTokenExpired(): boolean {
    const expiresAt = this.oauth2Service.oAuth2Token?.expires_at;
    return !!expiresAt && expiresAt < new Date();
  }

  constructor(
    private contextService: ContextService,
    private globalStore: GlobalStore,
    private oauth2Service: OAuth2Service,
    private insightsService: InsightsService,
    private session: SessionService,
    private onboardingStore: OnboardingStore,
    private feature: FeatureService
  ) {}

  async initialize() {
    try {
      this.setOAuth2ServiceKeys(this.officeEmail);

      if (
        !this.oauth2Service.requiredPropertiesPopulated &&
        (!this.oauth2Service.populateFromStorage() || this.isTokenExpired)
      ) {
        return this.refreshTokenOrHandleError();
      }

      await this.populateContext();
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.insightsService.trackException({ error });
      }
    }
  }

  private setOAuth2ServiceKeys(officeEmail: string): void {
    const partitionKey = Office.context.partitionKey ?? officeEmail;
    this.oauth2Service.storagePartitionKey = partitionKey;
    this.oauth2Service.encryptPassword = officeEmail;
  }

  private refreshTokenOrHandleError(): void {
    this.oauth2Service
      .refreshToken()
      .pipe(
        timeout(15000),
        catchError(async () => {
          return of(undefined);
        }),
      )
      .subscribe(async (result) => {
        if (result) {
          await this.populateContext();
        } else { 
          this.oauth2Service.clearAuth();
          this.session.clearSessionCache();
        }
      });
  }

  private async populateContext() {
    if (!this.oauth2Service.requiredPropertiesPopulated || !this.oauth2Service.mxUserEmail) {
      return;
    }

    this.contextService.token =
      this.oauth2Service.oAuth2Token?.access_token ?? '';
    const loginDetailsList = await lastValueFrom(
      this.oauth2Service.getLoginDetails(this.oauth2Service.mxUserEmail),
    );

    if (!this.oauth2Service.requiredPropertiesPopulated || !loginDetailsList) {
      return;
    }

    const loginDetails = loginDetailsList.find(
      (o) => o.tenant.id === this.oauth2Service.loginDetails?.tenant.id,
    );
    if (!loginDetails) {
      return;
    }

    this.oauth2Service.populateContextOAuthData();
    this.oauth2Service.setStorageLoginDetails(loginDetails);

    await lastValueFrom(this.getSessionAndStartAppInsights());
  }

  private getSessionAndStartAppInsights() {
    return this.session.getInfo().pipe(
      timeout(15000),
      retry({ count: 2, delay: 2000 }),
      catchError(async (error) => {
        this.insightsService?.trackException({ error });
        return undefined;
      }),

      tap((result) => {
        if (!result) {
          return;
        }
        this.oauth2Service.mxUserId = result.user.id;
        this.globalStore.setSession(result);

        this.initializeInsightsService();
        this.initializeFeatureFlags();
      }),
    );
  }

  private initializeInsightsService(): void {
    const outlookVersion = Office.context.platform?.toString() ?? 'unknown';
    this.insightsService.setContextData(
      {'outlook-version': outlookVersion },
    );

    this.insightsService.trackPageView({
      name: 'outlook-version',
      pageType: outlookVersion,
    });
  }

  private initializeFeatureFlags(): void {
    const showOnboarding = this.feature.isFeatureOn(
      'integration-o365-plg-onboarding-shown',
      false,
    );
    this.onboardingStore.showOnboarding(showOnboarding);
  }
}
