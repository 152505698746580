import { Inject, Injectable } from '@angular/core';
import { APP_VERSION, DEBUG, PRODUCT } from '../data-access.module';
import { Integration, KendoDialogSettings } from '../models';
import { Observable, map, of } from 'rxjs';

declare const DEV_TOKEN: string;
declare const DEV_WEBSITE: string;
declare const DEV_API: string;

@Injectable()
export class ContextService {
  website = DEV_WEBSITE;
  api = DEV_API;
  identityToken = '';
  version?: string;
  embedded = false;
  debug = false;
  token = DEV_TOKEN;
  resources: { [Key: string]: string } = {};
  isCloud = false;
  featureClientId?: string;
  hasCustomerHealthServiceUser = false;
  refresh_token = '';
  alias = '';
  integration?: Integration;
  timeFormat?: string;
  dateFormat?: string;
  dataCenter?: string;
  customerNumber?: string;
  tenantId?: string;

  constructor(
    @Inject(DEBUG) debug: boolean,
    @Inject(APP_VERSION) private appVersion: string,
    @Inject(PRODUCT) product: string,
  ) {
    this.debug = debug;
    try {
      if (product === 'outlook') {
        this.loadOutlook();
        return;
      }
      this.loadCore();
    } catch (error) {
      console.warn('Context error:', error);
    }
  }

  private loadCore() {
    if (top) {
      if (top.maxAppPath) {
        this.embedded = true;
        this.token = '';
        this.website = top.maxAppPath;
      }
      if (top.headerData) {
        this.identityToken = top.headerData.leadModuleIdentityToken;
        this.featureClientId = top.headerData.featureClientId;
        this.isCloud = top.headerData.IsCrmLive?.toLowerCase() === 'true';
        this.hasCustomerHealthServiceUser =
          top.headerData.hasCustomerHealthServiceUser?.toLowerCase() === 'true';
        this.timeFormat = top.headerData.timeFormat?.replace('tt', 'a');
        this.dateFormat =
          top.headerData.dateTimeFormatType === 'long'
            ? top.headerData.longDateFormatWithoutWeekday
            : top.headerData.yeardateformat;
        this.dataCenter = top.headerData.dataCenterID;
        this.customerNumber = top.headerData.customerNumber;
      }
      if (top.pendoFields) {
        this.version = top.pendoFields.version;
      }
      if (top.$resources) {
        this.resources = top.$resources;
      }
      const apiElement = top.document.getElementById(
        'idWebAPIURL',
      ) as HTMLInputElement;
      if (apiElement?.value) {
        this.api = apiElement.value;
      }
    }
  }

  startDialogMode(): void {
    if (top && top.$page) {
      top.$page.MaxKeyAccess.DisableModuleCommand();
      top.$page.MaxKeyAccess.DisableInsertCommand();
      top.$page.MaxKeyAccess.EnableEditCommand();
    }
  }

  endDialogMode(): void {
    if (top && top.$page) {
      top.$page.MaxKeyAccess.EnableModuleCommand();
      top.$page.MaxKeyAccess.EnableInsertCommand();
      top.$page.MaxKeyAccess.DisableEditCommand();
    }
  }

  showLegacyKendoModal(
    settings: KendoDialogSettings,
  ): Observable<unknown | null> {
    if (this.embedded) {
      return new Observable((observer) => {
        top?.MaxShowKendoModal.open(
          settings,
          (result) => {
            observer.next(result);
            observer.complete();
          },
          () => {
            observer.next(null);
            observer.complete();
          },
        );
      });
    }
    return of(null);
  }

  showLegacyTeamOwnerSelector(): Observable<string[] | null> {
    if (this.embedded && top) {
      const settings: KendoDialogSettings = {
        ID: 'PipelineVisualSelectionDialog',
        url: `${top.maxAppPath}/mvc/PipelineVisualSelection/PipelineVisualSelection/Index`,
        height: top.window.outerHeight * 0.8,
        width: top.window.outerWidth * 0.5,
        draggable: true,
        scrollable: true,
      };

      return this.showLegacyKendoModal(settings).pipe(
        map((result) => {
          if (result && Array.isArray(result)) {
            return result;
          }
          return null;
        }),
      );
    }
    return of(null);
  }

  getEdition(): string {
    return (
      (top?.document.getElementById('idEditionParameter') as HTMLInputElement)
        ?.value ?? ''
    );
  }

  openHelp(id: string): void {
    top?.window.postMessage(
      JSON.stringify({
        type: 'help',
        id,
      }),
      '*',
    );
  }

  loadOutlook(): void {
    this.version = this.appVersion;
    this.isCloud = true;
  }

  loadIntegration(integration: Integration): void {
    this.api = integration.api;
    this.identityToken = integration.identityToken;
    this.integration = integration;
  }

  clearAuthentication() {
    this.api = '';
    this.website = '';
    this.alias = '';
    this.token = '';
    this.refresh_token = '';
  }
}
