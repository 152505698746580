import { FormControl } from '@angular/forms';
import { LayoutElementComponent } from '../components/layout-element.component';
import { Field, ListItem, SelectorField } from '@maximizer/core/shared/domain';
import { Subject } from 'rxjs';

export class LayoutFormControl extends FormControl {
  private elements: LayoutElementComponent[] = [];
  readonly visibilityChange = new Subject<boolean>();
  hidden = false;

  constructor(public field: Field) {
    super();
  }

  show(): void {
    this.hidden = false;
    this.elements.forEach((element) => element.show());
    this.visibilityChange.next(true);
  }

  hide(): void {
    this.hidden = true;
    this.elements.forEach((element) => element.hide());
    this.visibilityChange.next(false);
  }

  setOptions(options: ListItem<unknown>[]): void {
    if (this.field.type === 'selector') {
      (this.field as SelectorField<unknown>).options = options;
    }
  }

  updateField(field: Partial<Field>): void {
    this.field = { ...this.field, ...field };
    this.elements.forEach((element) => (element.field = this.field));
  }

  addElement(element: LayoutElementComponent): void {
    this.elements.push(element);
  }

  removeElement(element: LayoutElementComponent): void {
    const index = this.elements.indexOf(element);
    if (index > -1) {
      this.elements.splice(index, 1);
    }
  }

  get name(): string {
    return this.field.id;
  }

  get hasElements(): boolean {
    return this.elements.length > 0;
  }
}
