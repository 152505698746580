import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { Octopus, OpportunityForm } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import {
  OpportunityAbEntryDetailsReadRequest,
  OpportunityAbEntryDetailsResponse,
  OpportunityCreateMapper,
  OpportunityRevenueSum,
  OpportunityRevenueSumMapper,
  OpportunitySummary,
  OpportunitySummaryMapper,
  OpportunitySummaryResponse,
} from '@maximizer/outlook/shared/domain';

@Injectable()
export class OpportunityService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  save(opportunityForm: OpportunityForm): Observable<string | null> {
    const mapper = new OpportunityCreateMapper();
    const data = mapper.from(opportunityForm);
    const request: Octopus.OpportunityWriteRequest = {
      Opportunity: {
        Data: data,
      },
    };
    return this.http
      .post<Octopus.OpportunityWriteResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Opportunity.Data.Key ?? null;
          }

          throw new HttpErrorResponse({ error: 'Unsuccessful request' });
        }),
      );
  }

  getSummary(key: string): Observable<OpportunitySummary> {
    const query: Octopus.LogicalQuery<Octopus.Opportunity> = {
      $OR: [{ AbEntryKey: { $EQ: key } }, { ContactKey: { $EQ: key } }],
      $AND: [{ Status: { $IN: [0, 2] } }],
    };

    const fields = this.getSummaryScope();
    const request = {
      Configuration: Octopus.OpportunityReadDriver,
      Opportunity: {
        Scope: {
          Fields: fields,
        },
        Criteria: {
          SearchQuery: query,
          Top: 1,
        },
        OrderBy: {
          Fields: [{ LastModifyDate: 'DESC' }],
        },
      },
      'Opportunity.Count': {
        Scope: {
          Fields: {
            Key: 1,
            ForecastRevenue: {
              Value: 1,
              DisplayValue: 1,
              CurrencyCode: 1,
            },
          },
        },
        Criteria: {
          SearchQuery: query,
        },
      },

      Globalization: Octopus.DefaultGlobalization,
    };

    return this.http
      .post<OpportunitySummaryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new OpportunitySummaryMapper();
            return mapper.from(result);
          }

          return {
            opportunitiesAmount: 0,
            opportunitiesWorthValue: 0,
          };
        }),
      );
  }

  private getSummaryScope(): Octopus.Scope<Octopus.Opportunity> {
    return {
      Key: 1,
      Objective: 1,
      Description: 1,
      ForecastRevenue: {
        Value: 1,
        DisplayValue: 1,
        CurrencyCode: 1,
      },
      CloseDate: {
        Value: 1,
      },
      LastModifyDate: {
        Value: 1,
      },
      CurrentSalesStage: {
        ProbabilityClose: 1,
      },
    };
  }

  getOpenSum(key: string): Observable<OpportunityRevenueSum> {
    const openQuery: Octopus.LogicalQuery<Octopus.Opportunity> = {
      $OR: [{ AbEntryKey: { $EQ: key } }, { ContactKey: { $EQ: key } }],
      $AND: [{ Status: { $IN: [0, 2] } }],
    };
    const request: OpportunityAbEntryDetailsReadRequest = {
      Configuration: Octopus.OpportunityReadDriver,
      'Opportunity.Open': {
        Scope: {
          Fields: {
            '$SUM(corporateRevenue)/displayValue': 1,
          },
        },
        Criteria: {
          SearchQuery: openQuery,
        },
      }
    };

    return this.http
      .post<OpportunityAbEntryDetailsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => this.mapCompletedAndOpenOpportunitySum(result)),
      );
  }
  getOpenAndWonSum(key: string): Observable<OpportunityRevenueSum> {
    const wonQuery: Octopus.LogicalQuery<Octopus.Opportunity> = {
      $OR: [{ AbEntryKey: { $EQ: key } }, { ContactKey: { $EQ: key } }],
      $AND: [{ Status: { $IN: [3] } }],
    };

    const openQuery: Octopus.LogicalQuery<Octopus.Opportunity> = {
      $OR: [{ AbEntryKey: { $EQ: key } }, { ContactKey: { $EQ: key } }],
      $AND: [{ Status: { $IN: [0, 2] } }],
    };
    const request: OpportunityAbEntryDetailsReadRequest = {
      Configuration: Octopus.OpportunityReadDriver,
      Opportunity: {
        Scope: {
          Fields: {
            '$SUM(corporateRevenue)/displayValue': 1,
          },
        },
        Criteria: {
          SearchQuery: wonQuery,
        },
      },
      'Opportunity.Open': {
        Scope: {
          Fields: {
            '$SUM(corporateRevenue)/displayValue': 1,
          },
        },
        Criteria: {
          SearchQuery: openQuery,
        },
      },
    };

    return this.http
      .post<OpportunityAbEntryDetailsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => this.mapCompletedAndOpenOpportunitySum(result)),
      );
  }

  private mapCompletedAndOpenOpportunitySum(result: OpportunityAbEntryDetailsResponse){
    if (result.Code === Octopus.ResponseStatusCode.Successful) {
      const mapper = new OpportunityRevenueSumMapper();
      return mapper.from(result);
    }

    return {
      current: '$0',
      completed: '$0',
    };
  }
}
