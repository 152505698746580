/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, ViewContainerRef } from '@angular/core';
import { KendoComponentDirective } from './kendo-component.directive';

@Directive({
  selector:
    'kendo-multiselecttree, kendo-dropdownlist, kendo-autocomplete, kendo-combobox, kendo-dropdowntree, kendo-multicolumncombobox, kendo-multiselect',
})
export class KendoPopupSettingsDirective extends KendoComponentDirective {
  constructor(element: ElementRef, view: ViewContainerRef) {
    super(element, view);
    if (this.component && this.component.popupSettings) {
      this.component.popupSettings.appendTo = 'component';
    }
  }
}
