import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Entity, FieldSet, SelectorField } from '@maximizer/core/shared/domain';
import { Subject } from 'rxjs';
import { KendoComponent } from '../../kendo/models/kendo-component';
import { OctopusValueHandler, ValueHandler } from '../handler/value-handler';
import { LayoutFormControl } from './layout-form-control';

export interface PickerOpenArguments {
  control: LayoutFormControl;
  field: SelectorField<unknown>;
}

export class LayoutFormGroup extends FormGroup {
  valueHandler: ValueHandler;
  pickerOpen = new Subject<PickerOpenArguments>();
  components: KendoComponent[] = [];

  constructor(public fields: FieldSet) {
    const controls: { [key: string]: FormControl | FormGroup } = {};
    for (const key of Object.keys(fields)) {
      const field = fields[key];
      controls[key] = new LayoutFormControl(field);
    }
    super(controls);
    this.valueHandler = new OctopusValueHandler(this);
  }

  openPicker(field: SelectorField<unknown>): void {
    const control = this.getControl(field.id);
    if (control) {
      this.pickerOpen.next({ control, field });
    }
  }

  getControl(id: string): LayoutFormControl | null {
    return this.get(id) as LayoutFormControl;
  }

  getErrors(): Entity {
    const errors: Entity = {};
    for (const name in this.controls) {
      const control = this.controls[name];
      if (control.errors) {
        errors[name] = control.errors;
      }
    }
    return errors;
  }

  setRequiredControl(id: string, required: boolean): void {
    let control = this.getControl(id);
    if (control) {
      control.field.required = required;
      if (control.field.metadata?.type?.endsWith('Key')) {
        const objectField = id.substring(0, id.length - 3);
        const objectControl = this.getControl(objectField);
        if (objectControl) {
          objectControl.updateField({ required });
          control = objectControl;
        }
      }
      if (required) {
        control.addValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
      }
      control.markAsDirty();
      control.updateValueAndValidity({ emitEvent: false });
    }
  }

  getControlOrRelated(id: string): LayoutFormControl | null {
    const control = this.getControl(id);
    let relatedControl: LayoutFormControl | null = null;
    if (control) {
      if (control.field.metadata?.type?.endsWith('Key')) {
        const objectField = id.substring(0, id.length - 3);
        relatedControl = this.getControl(objectField);
      }
    }
    return relatedControl ?? control;
  }

  focusFirstInvalidControl(): void {
    this.components
      .find((component) => component.formControl?.invalid)
      ?.focus();
  }

  focusControl(id: string): void {
    this.components
      .find(
        (component) =>
          component.formControl instanceof LayoutFormControl &&
          component.formControl?.name === id,
      )
      ?.focus();
  }
}
