import { Octopus } from '../..';
import { Entity } from './entity';
import { KeyAndDisplay } from './key-and-display';

export interface EmailValidationResponse {
  Code: number;
  UserProfileValidation: UserProfileEmailValidation;
}

export interface UserProfileEmailValidation {
  Data: Array<EmailStatusGroup>;
}

export interface EmailStatusGroup {
  Key: string;
  DisplayValue: string;
  Email1Status: EmailStatus;
  Email2Status: EmailStatus;
  Email3Status: EmailStatus;
}

export interface EmailStatus {
  DisplayValue: string;
  Key: number;
}

export interface EmailValidationReadRequest {
  UserProfileValidation: Octopus.ReadRequest<Pick<Octopus.EmailValidation, Octopus.EmailValidationFields>>;
}

export enum EmailValidationFields {
  Key = 'Key',
  DisplayValue = 'DisplayValue',
  Email1Status = 'Email1Status',
  Email2Status = 'Email2Status',
  Email3Status = 'Email3Status'
}

export interface EmailValidation extends Entity {
  [EmailValidationFields.Key]: string;
  [EmailValidationFields.DisplayValue]: string;
  [EmailValidationFields.Email1Status]: KeyAndDisplay;
  [EmailValidationFields.Email2Status]: KeyAndDisplay;
  [EmailValidationFields.Email3Status]: KeyAndDisplay;
}