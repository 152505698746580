import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  SessionService,
  UserService,
} from '@maximizer/core/shared/data-access';
import {
  ListItem,
  GroupedListItem,
  LeadSearch,
  AbEntrySearch,
} from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
  StringValidator,
} from '@maximizer/core/shared/ui';

import { forkJoin, finalize, catchError, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentService } from '@maximizer/outlook/appointment/data-access';
import {
  AbEntrySearchService,
  LeadSearchService,
} from '@maximizer/core/global-search/data-access';
import {
  LeadService,
  AbEntryService,
} from '@maximizer/outlook/shared/data-access';
import { RemoveTagEvent } from '@progress/kendo-angular-dropdowns';
import {
  AppointmentModuleConfiguration,
  AppointmentForm,
  OutlookLeadSearch,
  OutlookAbEntrySearch,
} from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'maximizer-add-appointment',
  templateUrl: './add-appointment.component.html',
})
export class AddAppointmentComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;

  constructor(
    private appointmentService: AppointmentService,
    private session: SessionService,
    private translate: TranslateService,
    private userService: UserService,
    private abEntrySearchService: AbEntrySearchService,
    private leadSearchService: LeadSearchService,
    private leadService: LeadService,
    private abEntryService: AbEntryService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.configuration = {
      options: {
        categories: [this.noneValue],
        results: [this.noneValue],
        locations: [this.noneValue],
      },
      users: [],
    };
  }

  configuration: AppointmentModuleConfiguration;
  form?: FormGroupWrapper<AppointmentForm>;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  currentUser: GroupedListItem<string> = {
    id: '',
    name: '',
    isDisabled: true,
    group: 'user',
  };

  loading = true;
  canSave = false;

  noneValue: ListItem<string> = {
    id: '-1',
    name: this.translate.instant('outlook.forms.none'),
  };

  dateFormat = 'dd MMMM yyyy';
  timeFormat = 'hh:mm a';

  private _abEntryKey: string | null = null;
  private _leadKey: string | null = null;

  public filterableAttendeeList: GroupedListItem<string>[] = [];
  public autocompleteLoading = false;

  private _icons = {
    user: 'icon-solid icon-user',
    lead: 'icon-solid icon-user-magnifying-glass',
    abentry: 'icon-solid icon-address-book',
    company: 'icon-regular icon-building',

    getIcon(type: string, isCompany = false) {
      switch (type) {
        case 'user':
          return this.user;
        case 'abentry':
          if (isCompany) return this.company;
          return this.abentry;
        case 'lead':
          return this.lead;
        default:
          return '';
      }
    },
  };

  @Input() type!: 'lead' | 'abentry';

  @Input()
  set id(id: string) {
    if (this.type == 'lead') this._leadKey = decodeURI(id);
    if (this.type == 'abentry') this._abEntryKey = decodeURI(id);
  }
  get id() {
    return this._leadKey ?? this._abEntryKey ?? '';
  }

  ngOnInit(): void {
    this.setForm();

    this.form?.patch({
      location: this.noneValue.id,
      category: this.noneValue.id,
      result: this.noneValue.id,
    });

    if (this._leadKey || this._abEntryKey) this.loadData();
  }

  private setForm(): void {
    const dateStart: Date = this.getNextRoundedDateTime();
    const dateEnd: Date = new Date(dateStart.getTime() + 30 * 60000);

    this.form = FormWrapperBuilder.group<AppointmentForm>(
      {
        key: null,
        creator: this.currentUser?.id,
        subject: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(1024),
              StringValidator.NotEmpty(),
            ]),
          },
        ],
        date: [dateStart, [Validators.required]],
        startTime: [dateStart, [Validators.required]],
        endTime: [dateEnd, [Validators.required]],
        location: '-1',
        assignedUser: [null, Validators.required],
        attendees: [null, [Validators.required]],
        description: null,

        category: null,
        result: null,
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
      },
    );
  }

  private loadData(): void {
    forkJoin({
      appointmentOptions: this.appointmentService.getFieldOption(),
      users: this.userService.getActiveUsers(),
      currentUser: this.session.getCurrentUser(),
      mainObject: this.loadLeadOrAbEntryService(),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (data == null) return;

        this.currentUser = {
          id: data.currentUser.key,
          name: data.currentUser.name,
          isDisabled: true,
          icon: 'icon-user',
          group: 'user',
        };

        this.loadDefaultAttendees(data.mainObject);

        const { categories, results, locations } = data.appointmentOptions;
        categories.unshift(this.noneValue);
        results.unshift(this.noneValue);
        locations.unshift(this.noneValue);

        this.configuration = {
          options: {
            categories: categories ?? [this.noneValue],
            results: results ?? [this.noneValue],
            locations: locations ?? [this.noneValue],
          },
          users: data.users ?? [],
        };

        this.form?.patch({
          assignedUser: this.currentUser.id,
          category: this.noneValue.id,
          result: this.noneValue.id,
        });
      });
    if (!this.configuration) return;
  }

  onStartChange() {
    if (!this.form) return;
    this.form.patch({
      endTime: this.form?.controls.startTime.value,
    });
  }

  disableAttendees(itemArgs: {
    dataItem: GroupedListItem<string>;
    index: number;
  }) {
    return itemArgs.dataItem.isDisabled;
  }

  handleRemoveAttendees(removedItem: RemoveTagEvent) {
    const idx = this.filterableAttendeeList.indexOf(removedItem.dataItem);
    this.filterableAttendeeList.splice(idx, 1);
  }

  resetAttendeesToSelected() {
    this.filterableAttendeeList = this.form?.value.attendees ?? [];
  }

  previousAutocompleteInput = '';
  handleAttendeesAutoComplete(inputText: string) {
    if (this.previousAutocompleteInput != inputText) {
      this.previousAutocompleteInput = inputText;
    }
    if (inputText.length < 3) {
      this.autocompleteLoading = false;
      return;
    }
    this.autocompleteLoading = true;

    setTimeout(() => {
      if (this.previousAutocompleteInput == inputText) {
        forkJoin({
          abentries: this.abEntrySearchService.search(inputText, true),
          leads: this.leadSearchService.search(inputText, true),
        })
          .pipe(
            catchError(async () => {
              this.notification.show('load', 'error');
            }),
            finalize(() => (this.autocompleteLoading = false)),
          )
          .subscribe((data) =>
            this.filterAutocompleteLoadedData(
              inputText,
              data?.abentries,
              data?.leads,
            ),
          );
      }
    }, 1000);
  }
  private filterAutocompleteLoadedData(
    inputText: string,
    abentries?: AbEntrySearch[],
    leads?: LeadSearch[],
  ) {
    if (!abentries && !leads) return;
    const attendees: GroupedListItem<string>[] = [];

    this.filterUsersToAttendees(inputText, attendees);
    this.filterAttendees(abentries, attendees, 'abentry');
    this.filterAttendees(leads, attendees, 'lead');

    attendees.sort((a, b) => a.name.localeCompare(b.name));
    this.filterableAttendeeList = attendees;
  }
  save(): void {
    if (this.form) {
      this.form.patch({
        creator: this.currentUser.id,
        location: this.transformToNullWhenNone(this.form.value.location),
        category: this.transformToNullWhenNone(this.form.value.category),
        result: this.transformToNullWhenNone(this.form.value.result),
      });

      const validation = this.form.validate();
      if (!validation.valid) return;

      this.loading = true;
      this.appointmentService
        .create(this.form.value)
        .pipe(
          catchError(async () => {
            this.notification.show('create', 'error');
          }),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((result) => {
          if (!result) return;
          this.notification.show('create', 'success');
          this.router.navigate(['../'], { relativeTo: this.route });
        });
    }
  }

  cancel(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private loadLeadOrAbEntryService(): Observable<
    OutlookAbEntrySearch | OutlookLeadSearch | null
  > {
    if (this._leadKey) return this.leadService.getByKey(this._leadKey ?? '');
    if (this._abEntryKey)
      return this.abEntryService.getByKey(this._abEntryKey ?? '');
    return of(null);
  }

  private loadDefaultAttendees(
    mainObject: OutlookAbEntrySearch | OutlookLeadSearch | null,
  ) {
    this.filterableAttendeeList = [this.currentUser];
    const attendeeObj: GroupedListItem<string> = {
      id: mainObject?.key ?? '',
      name: mainObject?.name ?? '',
      isDisabled: false,
      group: '',
    };

    if (this._leadKey) {
      attendeeObj.icon = this._icons.lead;
      attendeeObj.group = 'lead';
      this.filterableAttendeeList.push(attendeeObj);
    }
    if (this._abEntryKey) {
      const abentry = mainObject as OutlookAbEntrySearch;
      if (abentry.type == 'Company') {
        attendeeObj.name = mainObject?.companyName ?? '';
        attendeeObj.icon = this._icons.company;
        attendeeObj.group = 'abentry';
      } else {
        attendeeObj.icon = this._icons.abentry;
        attendeeObj.group = 'abentry';
      }
    }
    this.filterableAttendeeList.push(attendeeObj);
    this.form?.patch({
      attendees: [this.currentUser, attendeeObj],
    });
  }

  private getNextRoundedDateTime() {
    const now = new Date();
    if (now.getMinutes() < 30) {
      now.setMinutes(30, 0, 0);
    } else {
      now.setMinutes(0, 0, 0);
      now.setHours(now.getHours() + 1);
    }
    return now;
  }

  private transformToNullWhenNone(input: string | null) {
    if (input == this.noneValue.id) return null;
    return input;
  }

  private filterUsersToAttendees(
    inputText: string,
    attendees: GroupedListItem<string>[],
  ) {
    if (!this.configuration.users) return;
    if ((this.configuration.users?.length ?? 0) === 0) return;

    const filtered = this.configuration.users.filter(
      (o) =>
        o.name.toLowerCase().includes(inputText.toLowerCase()) &&
        o.key != this.currentUser.id,
    );
    filtered.forEach((obj) => {
      attendees.push({
        id: obj.key ?? '',
        name: obj.name,
        icon: this._icons.user,
        isDisabled: false,
        group: 'user',
      });
    });
  }

  private filterAttendees(
    data: AbEntrySearch[] | LeadSearch[] | null | undefined,
    attendees: GroupedListItem<string>[],
    attendeeType: string,
  ) {
    if (data === null || data === undefined) return;
    if (data.length === 0) return;

    data.forEach((obj) => {
      let isCompany = false;
      if (attendeeType === 'abentry') {
        const abentry = obj as AbEntrySearch;
        obj.name = abentry.displayValue;
        isCompany = abentry.type === 'Company';
      }
      const attendee = {
        id: obj.key ?? '',
        name: obj.name ?? '',
        icon: this._icons.getIcon(attendeeType, isCompany),
        isDisabled: false,
        group: attendeeType,
      };
      attendees.push(attendee);
    });
  }
}
