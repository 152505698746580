import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { SelectorField } from '@maximizer/core/shared/domain';
import {
  ComboBoxComponent,
  DropDownListComponent,
  MultiSelectComponent,
  PreventableEvent,
  VirtualizationSettings,
} from '@progress/kendo-angular-dropdowns';
import { finalize, tap } from 'rxjs';
import { DisposableComponent } from '../../components/base/disposable.component';
import { LayoutFormControl, LayoutFormGroup } from '../classes';
import { ListHandler } from '../handler/list-handler';

@Component({
  selector: 'maximizer-selector-field',
  template: `
    <ng-container [ngSwitch]="field.selection" [formGroup]="form.control">
      @switch (field.selection) {
        @case ('dialog') {
          <kendo-formfield [ngClass]="field.labelPosition ?? 'left'">
            <kendo-label
              maximizerFieldLabel
              [field]="field"
              [for]="input"
              [required]="field.required ?? false"
            >
            </kendo-label>
            <kendo-combobox
              #input
              textField="name"
              valueField="id"
              class="dialog-selector"
              [data]="field.options"
              [formControlName]="field.id"
              [ngClass]="field.cssClass"
              [class.readOnly]="field.readonly"
              (click)="openPicker()"
            ></kendo-combobox>
            @if (field.hint) {
              <kendo-formhint>{{ field.hint }}</kendo-formhint>
            }
            <kendo-formerror
              >{{
                'JSS_GenTxtPleaseSelect'
                  | resourceOrTranslate: 'error.selectField'
              }}
              {{ field.name }}</kendo-formerror
            >
          </kendo-formfield>
        }
        @case ('suggest') {
          <kendo-formfield [ngClass]="field.labelPosition ?? 'left'">
            <kendo-label
              maximizerFieldLabel
              [field]="field"
              [for]="input"
              [required]="field.required ?? false"
            >
            </kendo-label>
            <kendo-combobox
              #input
              textField="name"
              valueField="id"
              [data]="field.options"
              [allowCustom]="true"
              [valuePrimitive]="true"
              [suggest]="true"
              [virtual]="virtualization"
              [readonly]="field.readonly"
              [clearButton]="field.clearButton ?? true"
              [formControlName]="field.id"
              [ngClass]="field.cssClass"
              [class.readOnly]="field.readonly"
              (open)="open($event)"
            ></kendo-combobox>
            @if (field.hint) {
              <kendo-formhint>{{ field.hint }}</kendo-formhint>
            }
            <kendo-formerror
              >{{
                'JSS_GenTxtPleaseSelect'
                  | resourceOrTranslate: 'error.selectField'
              }}
              {{ field.name }}</kendo-formerror
            >
          </kendo-formfield>
        }
        @case ('multiple') {
          <kendo-formfield [ngClass]="field.labelPosition ?? 'left'">
            <kendo-label
              maximizerFieldLabel
              [field]="field"
              [for]="input"
              [required]="field.required ?? false"
            >
            </kendo-label>
            <kendo-multiselect
              #input
              class="max-h-36 overflow-y-auto"
              textField="name"
              valueField="id"
              [data]="field.options"
              [checkboxes]="true"
              [autoClose]="false"
              [clearButton]="field.clearButton ?? true"
              [virtual]="virtualization"
              [filterable]="field.options.length > 0"
              [kendoDropDownFilter]="{
                caseSensitive: false,
                operator: 'contains'
              }"
              [kendoMultiSelectSummaryTag]="100"
              [readonly]="field.readonly"
              [formControlName]="field.id"
              [ngClass]="field.cssClass"
              [class.readOnly]="field.readonly"
              (open)="open($event)"
            >
              <ng-template kendoMultiSelectHeaderTemplate let-item>
                @if (field.options.length) {
                  <div class="flex items-center p-2">
                    <input
                      rounded="none"
                      type="checkbox"
                      #checkAll
                      kendoCheckBox
                      [indeterminate]="
                        input.value.length > 0 &&
                        input.value.length !== field.options.length
                      "
                      [checked]="input.value.length === field.options.length"
                      (change)="checkAllChanged(checkAll.checked)"
                    />
                    <kendo-label
                      class="k-checkbox-label"
                      [for]="checkAll"
                      [text]="
                        'JSS_GenCheckAll'
                          | resourceOrTranslate: 'texts.checkAll'
                      "
                    ></kendo-label>
                  </div>
                }
              </ng-template>

              <ng-template kendoMultiSelectGroupTagTemplate let-items>
                <div
                  kendoTooltip
                  [popupTarget]="element"
                  [tooltipTemplate]="selectionTooltip"
                  position="bottom"
                  showOn="hover"
                  filter="span"
                  [closable]="true"
                  [callout]="false"
                >
                  <span>
                    {{
                      'JSS_GenTxtMoreSelected'
                        | resourceOrTranslate: 'texts.moreSelected'
                        | format: [items.length]
                    }}
                  </span>
                  <ng-template #selectionTooltip>
                    <div class="overflow-y-auto max-h-96">
                      <p class="text-xs">
                        @for (item of items; track $index) {
                          @if ($index > 0) {
                            ,
                          }
                          {{ item.name }}
                        }
                      </p>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </kendo-multiselect>
            @if (field.hint) {
              <kendo-formhint>{{ field.hint }}</kendo-formhint>
            }
            <kendo-formerror
              >{{
                'JSS_GenTxtPleaseSelect'
                  | resourceOrTranslate: 'error.selectField'
              }}
              {{ field.name }}</kendo-formerror
            >
          </kendo-formfield>
        }
        @default {
          <kendo-formfield [ngClass]="field.labelPosition ?? 'left'">
            <kendo-label
              maximizerFieldLabel
              [field]="field"
              [for]="input"
              [required]="field.required ?? false"
            >
            </kendo-label>
            <kendo-dropdownlist
              #input
              textField="name"
              valueField="id"
              [data]="field.options"
              [valuePrimitive]="true"
              [readonly]="field.readonly"
              [formControlName]="field.id"
              [ngClass]="field.cssClass"
              [class.readOnly]="field.readonly"
              [virtual]="virtualization"
              (open)="open($event)"
            ></kendo-dropdownlist>
            @if (field.hint) {
              <kendo-formhint>{{ field.hint }}</kendo-formhint>
            }
            <kendo-formerror
              >{{
                'JSS_GenTxtPleaseSelect'
                  | resourceOrTranslate: 'error.selectField'
              }}
              {{ field.name }}</kendo-formerror
            >
          </kendo-formfield>
        }
      }
    </ng-container>
  `,
  styles: [
    `
      :host {
        --kendo-chip-solid-base-bg: transparent;
        --kendo-chip-border-width: 0;
        --kendo-chip-solid-base-text: var(--core-text);

        ::ng-deep {
          .k-chip.k-chip-md {
            padding-inline: 0;

            .k-chip-content {
              padding-block: 0.25rem;
            }

            .k-chip-actions {
              @apply mr-1;
            }
          }

          kendo-combobox.dialog-selector {
            input {
              @apply cursor-pointer;
            }
            button {
              @apply hidden;
            }
          }

          .k-multiselect {
            .k-list .k-list-item {
              @apply pl-6;
            }
          }

          .k-dropdownlist,
          .k-combobox,
          .k-multiselect {
            &.readOnly {
              @apply cursor-text border-none hover:shadow-none focus:shadow-none focus-within:shadow-none;
              .k-input-button {
                @apply hidden;
              }
            }
          }
        }

        @apply w-full;
      }
    `,
  ],
  providers: [ListHandler],
})
export class SelectorFieldComponent
  extends DisposableComponent
  implements OnChanges, AfterViewInit
{
  @Input({ required: true })
  field!: SelectorField<unknown>;

  @ViewChild('input')
  input!: ComboBoxComponent | DropDownListComponent | MultiSelectComponent;

  readonly virtualization: VirtualizationSettings = {
    itemHeight: 36,
  };

  constructor(
    readonly form: FormGroupDirective,
    readonly element: ElementRef,
    private handler: ListHandler,
  ) {
    super();
  }

  ngOnChanges(): void {
    const control = this.form.control.get(this.field.id);

    if (control instanceof LayoutFormControl) {
      this.handler.handleChanges(control);
      this.handler.checkOptions(control);

      this.addSubscription(
        control.visibilityChange.subscribe((visible) => {
          if (visible) {
            this.handler.checkOptions(control);
          }
        }),
      );
    }
  }

  ngAfterViewInit(): void {
    if (
      this.field.selection === 'suggest' &&
      this.field.maxlength &&
      this.input instanceof ComboBoxComponent
    ) {
      this.input.searchbar.input.nativeElement.setAttribute(
        'maxlength',
        this.field.maxlength.toString(),
      );
    }
    if (
      this.field.selection === 'dialog' &&
      this.input instanceof ComboBoxComponent
    ) {
      this.input.searchbar.input.nativeElement.setAttribute('readonly', '');
    }
  }

  open(event: PreventableEvent): void {
    event.preventDefault();
    this.loadOptions(true);
  }

  loadOptions(open = false): void {
    this.input.loading = true;

    this.addSubscription(
      this.handler
        .getOptions(this.field)
        .pipe(
          tap((options) => {
            this.field.options = options;
            this.input?.toggle(open);
          }),
          finalize(() => (this.input.loading = false)),
        )
        .subscribe(),
    );
  }

  openPicker(): void {
    if (this.form.control instanceof LayoutFormGroup) {
      this.form.control.openPicker(this.field);
    }
  }

  checkAllChanged(checked: boolean): void {
    const control = this.form.control.get(this.field.id);

    if (checked) {
      control?.setValue([...this.field.options]);
    } else {
      control?.setValue([]);
    }
  }
}
