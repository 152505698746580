import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  SessionService,
  UserService,
} from '@maximizer/core/shared/data-access';
import { ListItem } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
  StringValidator,
} from '@maximizer/core/shared/ui';

import { forkJoin, finalize, catchError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TaskService } from '@maximizer/outlook/task/data-access';
import {
  TaskForm,
  TaskModuleConfiguration,
} from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'maximizer-add-task',
  templateUrl: './add-task.component.html',
})
export class AddTaskComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;
  constructor(
    private taskService: TaskService,
    private session: SessionService,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  private _now = new Date();
  private _abEntryKey: string | null = null;
  private _leadKey: string | null = null;

  form?: FormGroupWrapper<TaskForm>;
  configuration?: TaskModuleConfiguration;

  loading = true;
  canSave = false;

  noneValue: ListItem<string> = {
    id: '-1',
    name: this.translate.instant('outlook.forms.none'),
  };

  fullFormat = 'dd MMMM yyyy HH:mm:ss';

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Input() type!: 'lead' | 'abentry';

  @Input()
  set id(id: string) {
    if (this.type == 'lead') this._leadKey = decodeURI(id);
    if (this.type == 'abentry') this._abEntryKey = decodeURI(id);
  }
  get id() {
    return this._leadKey ?? this._abEntryKey ?? '';
  }

  ngOnInit(): void {
    this.setForm();
    this.configuration = {
      options: {
        categories: [this.noneValue],
        results: [this.noneValue],
        priorities: [this.noneValue],
      },
      users: [],
    };

    this.form?.patch({
      priority: this.noneValue.id,
      category: this.noneValue.id,
      result: this.noneValue.id,
    });

    if (this._leadKey || this._abEntryKey) this.loadData();
  }

  transformToNullWhenNone(input: string | null) {
    if (input == this.noneValue.id) return null;
    return input;
  }

  save(): void {
    if (this.form) {
      this.form.patch({
        abEntryKey: this._abEntryKey,
        leadKey: this._leadKey,
        priority: this.transformToNullWhenNone(this.form.value.priority),
        category: this.transformToNullWhenNone(this.form.value.category),
        result: this.transformToNullWhenNone(this.form.value.result),
      });

      const validation = this.form.validate();
      if (!validation.valid) return;

      const priority = this.form.value.priority ?? this.noneValue.id;
      const category = this.form.value.category ?? this.noneValue.id;
      const result = this.form.value.result ?? this.noneValue.id;

      this.loading = true;
      this.taskService
        .create(this.form.value)
        .pipe(
          catchError(async () => {
            this.onSaveError(priority, category, result);
            this.notification.show('create', 'error');
          }),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((saveResult) => {
          if (saveResult == null) return;

          this.notification.show('create', 'success');
          this.router.navigate(['../'], { relativeTo: this.route });
        });
    }
  }

  cancel(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private onSaveError(priority: string, category: string, result: string) {
    this.form?.patch({
      priority: priority,
      category: category,
      result: result,
    });
  }

  private setForm(): void {
    this.form = FormWrapperBuilder.group<TaskForm>(
      {
        key: null,
        dueDate: [this._now, [Validators.required]],
        activity: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              StringValidator.NotEmpty(),
            ]),
            updateOn: 'blur',
          },
        ],
        priority: '-1',
        category: '-1',
        result: '-1',
        assignedUser: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              StringValidator.NotEmpty(),
            ]),
            updateOn: 'blur',
          },
        ],
        abEntryKey: null,
        leadKey: null,
        alarm: null,
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
      },
    );
  }

  private loadData(): void {
    forkJoin({
      interactionOptions: this.taskService.getFieldOption(),
      users: this.userService.getActiveUsers(),
      currentUser: this.session.getCurrentUser(),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (data == null) return;

        const { categories, results, priorities } = data.interactionOptions;
        categories.unshift(this.noneValue);
        results.unshift(this.noneValue);
        priorities.unshift(this.noneValue);

        this.configuration = {
          options: {
            categories: categories ?? [this.noneValue],
            results: results ?? [this.noneValue],
            priorities: priorities ?? [this.noneValue],
          },
          users: data.users ?? [],
        };

        this.form?.patch({
          assignedUser: data.currentUser.id,
          priority: this.noneValue.id,
          category: this.noneValue.id,
          result: this.noneValue.id,
        });
      });
    if (!this.configuration) return;
  }
}
