<maximizer-outlook-menu
  [id]="id"
  [pageType]="type"
  [pageTitle]="'outlook.appointment.add-title' | translate"
></maximizer-outlook-menu>
<div class="flex flex-col h-full">
  <maximizer-loader *ngIf="loading" type="legacy"></maximizer-loader>
  <form
    *ngIf="form"
    class="flex flex-col p-4 text-xs space-y-2"
    [formGroup]="form.group"
  >
    <kendo-formfield>
      <kendo-label
        [for]="subject"
        [text]="'outlook.appointment.subject' | translate"
        >*
      </kendo-label>
      <kendo-textbox
        formControlName="subject"
        #subject
        [maxlength]="1023"
        required
      ></kendo-textbox>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="date" [text]="'outlook.appointment.date' | translate"
        >*
      </kendo-label>
      <kendo-datepicker
        #date
        [format]="dateFormat"
        formControlName="date"
        required
        [navigation]="false"
        calendarType="classic"
      >
      </kendo-datepicker>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <div class="flex flex-row justify-between space-x-2">
      <kendo-formfield class="w-2/4">
        <kendo-label
          [for]="startTime"
          [text]="'outlook.appointment.startTime' | translate"
          >*</kendo-label
        >
        <kendo-timepicker
          #startTime
          [format]="timeFormat"
          formControlName="startTime"
          required
          (valueChange)="onStartChange()"
        >
        </kendo-timepicker>
        <kendo-formerror>{{
          'outlook.forms.requiredField' | translate
        }}</kendo-formerror>
      </kendo-formfield>
      <span class="my-auto pt-4">-</span>
      <kendo-formfield class="w-2/4">
        <kendo-label
          [for]="endTime"
          [text]="'outlook.appointment.endTime' | translate"
          >*</kendo-label
        >
        <kendo-timepicker
          #endTime
          [format]="timeFormat"
          formControlName="endTime"
          required
          [min]="form.controls.startTime.value"
        >
        </kendo-timepicker>
        <kendo-formerror>{{
          'outlook.forms.requiredField' | translate
        }}</kendo-formerror>
      </kendo-formfield>
    </div>

    <kendo-formfield>
      <kendo-label
        [for]="location"
        [text]="'outlook.appointment.location' | translate"
      >
      </kendo-label>

      <kendo-dropdownlist
        [data]="configuration.options.locations"
        textField="name"
        valueField="id"
        fillMode="solid"
        [valuePrimitive]="true"
        formControlName="location"
        #location
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="primaryContact"
        [text]="'outlook.appointment.assignedUser' | translate"
        >*
      </kendo-label>

      <kendo-dropdownlist
        [data]="configuration.users"
        textField="name"
        valueField="key"
        fillMode="solid"
        [valuePrimitive]="true"
        formControlName="assignedUser"
        #primaryContact
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.appointment.attendees' | translate"
        >*
      </kendo-label>
      <kendo-multiselect
        class="appointmentItems text-xs"
        textField="name"
        valueField="id"
        [filterable]="true"
        [data]="filterableAttendeeList"
        formControlName="attendees"
        [clearButton]="false"
        (filterChange)="handleAttendeesAutoComplete($event)"
        (removeTag)="handleRemoveAttendees($event)"
        (blur)="resetAttendeesToSelected()"
        (open)="resetAttendeesToSelected()"
        [loading]="autocompleteLoading"
        [itemDisabled]="disableAttendees"
      >
        <ng-template kendoMultiSelectNoDataTemplate *ngIf="autocompleteLoading">
          <maximizer-loader type="legacy"></maximizer-loader>
        </ng-template>

        <ng-template
          kendoMultiSelectTagTemplate
          let-dataItem
          kendoMultiSelectCustomItemTemplate
        >
          <em
            [class]="'fa-icons icon icon-solid icon-12 pr-1 ' + dataItem.icon"
          ></em>
          {{ dataItem.name }}
        </ng-template>

        <ng-template kendoMultiSelectItemTemplate let-dataItem>
          <em
            [class]="'fa-icons icon icon-solid icon-12 ' + dataItem.icon"
          ></em>
          {{ dataItem.name }}
        </ng-template>
      </kendo-multiselect>

      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.appointment.description' | translate">
      </kendo-label>
      <kendo-textarea
        [rows]="6"
        resizable="vertical"
        formControlName="description"
      ></kendo-textarea>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [text]="'outlook.appointment.category' | translate"
      ></kendo-label>
      <kendo-dropdownlist
        [data]="configuration.options.categories"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="category"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [text]="'outlook.appointment.result' | translate"
      ></kendo-label>
      <kendo-dropdownlist
        [data]="configuration.options.results"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="result"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>
  </form>

  <div
    class="bg-neutral-5 z-40 mt-auto flex self-end space-x-2 p-2 w-full sticky bottom-0"
  >
    <button
      kendoButton
      class="uppercase rounded ml-auto"
      themeColor="primary"
      [disabled]="!canSave"
      (click)="save()"
    >
      <span *ngIf="!loading; else loader"
        >{{ 'outlook.buttons.save' | translate }}
      </span>
      <ng-template #loader>
        <span>{{ 'outlook.buttons.save' | translate }} </span>
        <kendo-loader type="pulsing" themeColor="primary" size="small">
        </kendo-loader>
      </ng-template>
    </button>
    <button
      kendoButton
      rounded="large"
      class="uppercase rounded"
      themeColor="primary"
      (click)="cancel()"
      [disabled]="loading"
      fillMode="flat"
    >
      {{ 'outlook.buttons.cancel' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification
  #notification
  [closable]="true"
  entryId="appointment"
>
</maximizer-outlook-notification>
