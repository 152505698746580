import { Octopus } from '@maximizer/core/shared/domain';
import {
  OpportunityAbEntryDetailsResponse,
  OpportunitySummaryResponse,
} from '../models';
import {
  OpportunityForm,
  OpportunityRevenueSum,
  OpportunitySummary,
} from '../../../models';

export class OpportunityCreateMapper extends Octopus.ConfigurationMapper<
  OpportunityForm,
  Octopus.OpportunityCreate
> {
  from(source: OpportunityForm): Octopus.OpportunityCreate {
    return {
      Key: source.key,
      Objective: source.objective,
      AbEntryKey:
        source.companyKey && source.companyKey.trim()
          ? source.companyKey
          : source.abEntryKey,
      ContactKey: source.abEntryKey,
      Description: source.description,
      Status: source.status,
      SalesProcessSetupKey: source.processId,
      SalesStageSetupKey: source.stageId,
      Cost: source.cost,
      ForecastRevenue: source.forecastRevenue,
      ActualRevenue: source.actualRevenue,
      CloseDate: source.closeDate?.toISOString() ?? null,
    };
  }
}

export class OpportunitySummaryMapper extends Octopus.ConfigurationMapper<
  OpportunitySummaryResponse,
  OpportunitySummary
> {
  override from(source: OpportunitySummaryResponse): OpportunitySummary {
    const opportunityObj =
      source.Opportunity.Data?.length > 0 ? source.Opportunity.Data[0] : null;
    const opportunityAmount =
      source['Opportunity.Count']?.Data?.length > 0
        ? source['Opportunity.Count'].Data
        : [];

    const result: OpportunitySummary = {
      opportunitiesAmount: 0,
      opportunitiesWorthValue: 0,
    };

    if (opportunityObj) {
      result.opportunity = {
        key: opportunityObj.Key,
        objective: opportunityObj.Objective,
        description: opportunityObj.Description,
        closeDate: opportunityObj.CloseDate?.Value,
        lastModifyDate: opportunityObj.LastModifyDate?.Value,
        forecastRevenue: opportunityObj.ForecastRevenue?.Value,
        stageValue: 0,
      };
    }
    if (opportunityAmount) {
      result.opportunitiesAmount =
        opportunityAmount.length > 0 ? opportunityAmount.length - 1 : 0;

      if (result.opportunitiesAmount > 0) {
        const otherOpportunities = opportunityAmount.filter(
          (o) => o.Key != opportunityObj?.Key,
        );

        let worthValue = 0;
        otherOpportunities.map((o) => (worthValue += o.ForecastRevenue?.Value));
        result.opportunitiesWorthValue = worthValue;
      }

      if (result.opportunity && opportunityObj?.CurrentSalesStage) {
        result.opportunity.stageValue =
          opportunityObj.ForecastRevenue?.Value *
          (opportunityObj.CurrentSalesStage?.ProbabilityClose / 100);
      }
    }
    return result;
  }
}

export class OpportunityRevenueSumMapper extends Octopus.ConfigurationMapper<
  OpportunityAbEntryDetailsResponse,
  OpportunityRevenueSum
> {
  override from(
    source: OpportunityAbEntryDetailsResponse,
  ): OpportunityRevenueSum {

    const wonOpps =
    (source.Opportunity?.Data?.length ?? 0) ? source.Opportunity?.Data[0] : null;
    
    const openOpps =
      (source['Opportunity.Open']?.Data?.length ?? 0) > 0
        ? source['Opportunity.Open']?.Data[0]
        : null;
        
    return {
      current: openOpps?.['$SUM(corporateRevenue)/displayValue']?.toString(),
      completed: wonOpps?.['$SUM(corporateRevenue)/displayValue']?.toString(),
    };
  }
}
