<maximizer-outlook-menu
  [pageTitle]="'outlook.compose.title' | translate"
  [hideBack]="true"
  [forceWindowRefresh]="true"
></maximizer-outlook-menu>

<maximizer-outlook-compose-warning
  (syncConfigEmitter)="handleComposeValidation($event)"
>
</maximizer-outlook-compose-warning>
@if (loading) {
  <maximizer-loader type="legacy"></maximizer-loader>
}

@if (!loading && isVersionValid) {
  <div class="h-full w-full text-xs flex flex-col bg-neutral-10">
    <!-- Recipients -->
    <div class="m-2 bg-white rounded-sm pt-3 min-h-40">
      <div class="flex flex-row">
        <span class="subtitle-2 mb-2 pl-3">{{
          'outlook.compose.recipients'
            | translate: { count: outlookEmails.length }
        }}</span>
        <kendo-dropdownlist
          [data]="uniqueEmailsCardList"
          [(ngModel)]="selectedEmail"
          (valueChange)="onSelectedEmailValueChange($event)"
          textField="displayName"
          valueField="localId"
          fillMode="solid"
          [rounded]="'large'"
          size="small"
          class="w-24 ml-auto text-end mr-6 compose-entry-dropdown border-none"
          [popupSettings]="{ width: 200, height: 120, popupClass: 'mr-2' }"
        >
          <ng-template kendoDropDownListValueTemplate class="text-end ml-auto">
            <span class="caption">{{
              'outlook.compose.cards-dropdown'
                | translate
                  : {
                      page: selectedDropdownItemPosition,
                      total: uniqueEmailsCardList.length,
                    }
            }}</span>
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div class="w-full max-w-[200px] h-6 overflow-hidden text-ellipsis">
              <span
                class="text-body w-full max-w-[200px] overflow-hidden text-ellipsis"
                >{{ dataItem.displayName }}</span
              >
            </div>
          </ng-template>
        </kendo-dropdownlist>
      </div>

      <maximizer-outlook-skeleton
        *ngIf="showSkeleton"
        containerClasses="h-40 w-full rounded-md bg-neutral-20 mx-6 mb-2"
      ></maximizer-outlook-skeleton>

      <div class="flex flex-row w-full" *ngIf="!showSkeleton">
        <button
          kendoButton
          size="none"
          fillMode="flat"
          class="p-1 mr-1 my-auto"
          title="Navigate to card on the left"
          [disabled]="uniqueEmailsCardList.length <= 1 || !selectedEmail"
          (click)="navigateCardsLeft()"
        >
          <em class="fa-icons icon-solid icon-10 icon-chevron-left"></em>
        </button>

        <div
          class="rounded h-40 min-w-[225px] w-full flex flex-row my-2 border-neutral-30 border-solid border pt-3"
        >
          <div class="flex min-w-[225px] px-3 w-full" *ngIf="selectedEmail">
            <!-- Avatar -->
            <div class="flex-none w-8">
              <div
                class="avatar avatar-xs avatar-neutral-20"
                [ngClass]="
                  getAvatarClass(selectedEmail.type, selectedEmail.abentryType)
                "
              >
                <span
                  *ngIf="
                    selectedEmail.type === 'abentry' &&
                    selectedEmail.abentryType !== 'company'
                  "
                  >{{ getAvatarLetters(selectedEmail) | nameInitials }}</span
                >

                <img
                  *ngIf="selectedEmail.type === undefined"
                  alt="Email not in maximizer"
                  [src]="
                    cdn + '/assets/images/outlook/email-not-in-maximizer.svg'
                  "
                  class="w-3 m-auto"
                />
                <em
                  *ngIf="selectedEmail.type === 'lead'"
                  class="fa-icons icon icon-solid icon-12 icon-user-magnifying-glass m-auto"
                ></em>
                <em
                  *ngIf="selectedEmail.abentryType === 'company'"
                  class="fa-icons icon icon-regular icon-16 icon-building m-auto"
                ></em>
              </div>
            </div>

            <!-- Entry info -->
            <div
              class="flex flex-col h-full w-full space-y-0.5 ml-2 overflow-hidden text-ellipsis"
            >
              <div class="flex flex-row w-full overflow-hidden break-all h-4">
                <span
                  class="subtitle-1 overflow-hidden text-ellipsis whitespace-nowrap"
                  >{{ selectedEmail.displayName }}</span
                >
                <div
                  *ngIf="selectedEmail.isNewEntry"
                  class="bg-finserv-10 caption rounded-md w-7 h-4 ml-2 flex"
                >
                  <span class="m-auto">{{
                    'outlook.main.new' | translate
                  }}</span>
                </div>
              </div>

              <span
                class="caption text-neutral-70 overflow-hidden text-ellipsis flex-none"
                [title]="selectedEmail.emailAddress"
                >{{ selectedEmail.emailAddress }}</span
              >

              <span
                class="caption text-neutral-70 break-all pb-2"
                *ngIf="selectedEmail.type !== undefined"
                >{{
                  (selectedEmail.type === 'lead'
                    ? 'outlook.entries.lead'
                    : 'outlook.entries.' + selectedEmail.abentryType
                  ) | translate
                }}</span
              >

              <div
                *ngIf="
                  !showSkeletonCardData && selectedEmail.type !== undefined
                "
                class="flex flex-row"
              >
                <span class="caption text-neutral-70 break-all"
                  >{{ 'outlook.compose.card.last-contacted' | translate }}:
                </span>
                <span class="caption text-neutral-90 break-all pl-2">{{
                  (selectedEmailSummary.lastContacted
                    ? (lastContactMessage ?? '')
                    : 'outlook.compose.card.last-contacted-emptystate'
                  ) | translate: { days: lastContactDays }
                }}</span>
              </div>

              <div
                *ngIf="
                  !showSkeletonCardData && selectedEmail.type === 'abentry'
                "
                class="flex flex-row"
              >
                <span class="caption text-neutral-70 break-all"
                  >{{ 'outlook.compose.card.current-opportunity' | translate }}:
                </span>
                <span class="caption text-neutral-90 break-all pl-2">{{
                  selectedEmailSummary.currentOpp
                    ? selectedEmailSummary.currentOpp
                    : ('outlook.compose.card.current-opportunity-emptystate'
                      | translate)
                }}</span>
              </div>

              <div
                *ngIf="
                  !showSkeletonCardData && selectedEmail.type !== undefined
                "
                class="flex flex-row"
              >
                <span class="caption text-neutral-70 break-all"
                  >{{ 'outlook.compose.card.upcoming-task' | translate }}:
                </span>
                <span class="caption text-neutral-90 break-all pl-2">{{
                  selectedEmailSummary.task
                    ? selectedEmailSummary.task
                    : ('outlook.compose.card.upcoming-task-emptystate'
                      | translate)
                }}</span>
              </div>

              <div class="flex ml-auto pb-2" style="margin-top: auto">
                <button
                  kendoButton
                  *ngIf="selectedEmail.type !== undefined"
                  fillMode="flat"
                  (click)="
                    navigateToEntry(selectedEmail.id, selectedEmail.type)
                  "
                  class="pb-2 hover:bg-inserv-5 rounded-sm"
                >
                  <span class="text-inserv-100 text-button">{{
                    'outlook.compose.card.view-details' | translate
                  }}</span>
                </button>

                <kendo-dropdownbutton
                  *ngIf="selectedEmail.type === undefined"
                  [title]="'outlook.compose.card.add-to-maximizer' | translate"
                  [data]="addMenuItems"
                  size="medium"
                  fillMode="flat"
                  [buttonClass]="['hover:bg-inserv-5', 'rounded-sm', 'pb-2']"
                  (itemClick)="
                    navigateToAdd(
                      $event,
                      selectedEmail.emailAddress,
                      selectedEmail.displayName
                    )
                  "
                  [popupSettings]="{ popupClass: 'bg-inserv-100 mr-2' }"
                >
                  <span class="text-inserv-100 text-button">{{
                    'outlook.compose.card.add-to-maximizer' | translate
                  }}</span>
                  <ng-template
                    kendoDropDownButtonItemTemplate
                    let-dataItem
                    class="h-4 overflow-hidden"
                  >
                    <em [ngClass]="dataItem.icon" class="w-4 ml-1 mr-2"></em>
                    <span>{{
                      'outlook.main.actions.add-' + dataItem.id | translate
                    }}</span>
                  </ng-template>
                </kendo-dropdownbutton>
              </div>
            </div>
          </div>

          <div *ngIf="!selectedEmail" class="flex flex-col m-auto">
            <img
              alt="No emails added to message"
              [src]="cdn + '/assets/images/outlook/compose-card-emptystate.png'"
              class="w-11 min-w-11 m-auto"
            />
            <span
              class="caption w-[140px] max-w-[140px] text-center text-neutral-90 mt-2"
              >{{ 'outlook.compose.card.title-emptystate' | translate }}</span
            >
          </div>
        </div>

        <button
          kendoButton
          size="none"
          fillMode="flat"
          class="p-1 ml-1 my-auto"
          title="Navigate to card on the right"
          [disabled]="uniqueEmailsCardList.length <= 1 || !selectedEmail"
          (click)="navigateCardsRight()"
        >
          <em class="fa-icons icon-solid icon-10 icon-chevron-right"></em>
        </button>
      </div>
    </div>

    <!-- Save the email multiselect -->
      <div class="mx-2 mb-2 bg-white rounded-sm p-3 min-h-20">
      <span class="subtitle-2 mb-2">{{
        'outlook.compose.save-the-email' | translate
      }}</span>
      <maximizer-outlook-compose-save-to-email
        #saveToEmail
      ></maximizer-outlook-compose-save-to-email>
    </div>
    

    <!-- Add recipients autocomplete -->
    <div class="mx-2 bg-white rounded-sm py-3">
      <div class="px-3">
        <p class="subtitle-2">
          {{ 'outlook.compose.add-recipients' | translate }}
        </p>
        <maximizer-outlook-compose-add-email
          (emailAdded)="handleEmailAddedFromSearch($event)"
        >
        </maximizer-outlook-compose-add-email>
      </div>
    </div>
  </div>

  <ng-template
    #itemTemplate
    let-dataItem
    let-canNavigate="canNavigate"
    let-saveForMainCard="saveForMainCard"
  >
    <div class="mb-1 rounded min-h-10 min-w-200 w-auto flex flex-row">
      <maximizer-outlook-entry-list-item
        [dataItem]="dataItem"
      ></maximizer-outlook-entry-list-item>
    </div>
  </ng-template>

  <maximizer-outlook-notification
    entryId="entries"
    #notification
    [closable]="true"
  >
  </maximizer-outlook-notification>
}
